/*
Card for displaying a research token. Lets users claim here too.
*/

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BriefcaseIcon,
  EmojiHappyIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { usePrivy } from "@privy-io/react-auth";

import {
  AddToWallet,
  Copy,
  DeployTransaction,
  HeldBalance,
} from "../components";
import { addToWallet } from "../helpers/wallet";

import { optimizeIdentifier } from "../helpers/format";

import { NETWORK } from "../constants";

function ConfirmButton({ verified, toggleContractVerifyStatus }) {
  const tooltipClass =
    "tooltip mt-2 absolute w-1/5 rounded z-10 inline-block shadow-sm text-white p-1 bg-gray-900 opacity-sm duration-300 transition-opacity invisible font-medium py-2 px-3";
  let color;
  let text;
  let toolTipText;
  let buttonClass = "ml-1 font-medium hover:text-gray-500";

  if (!verified) {
    toolTipText = "This paper has not been verified for this contract.";
    text = "Unverified";
    color = "#eb2f96";
  } else {
    toolTipText = "This paper has been verified to match this contract.";
    text = "Verified";
    color = "#52c41a";
    buttonClass = "cursor-default " + buttonClass;
  }

  return (
    <div className="flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 border border-transparent rounded-br-lg">
      <div className="has-tooltip">
        <QuestionMarkCircleIcon className="w-5 h-5" />
        <span className={tooltipClass}>{toolTipText}</span>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          toggleContractVerifyStatus();
        }}
        className={buttonClass}
        style={{ color: color }}
      >
        {text}
      </button>
    </div>
  );
}

function ClaimButton({ claim, balance }) {
  const tooltipClass =
    "tooltip mt-2 absolute w-1/5 rounded z-10 inline-block shadow-sm text-white p-1 bg-gray-900 opacity-sm duration-300 transition-opacity invisible font-medium py-2 px-3";
  const tooltipText = "Click to initiate your claim.";
  if (!balance || !claim) {
    return (
      <div className="flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 cursor-not-allowed">
        <BriefcaseIcon
          className="w-5 h-5 text-gray-700 cursor-pointer"
          aria-hidden="true"
        />
        <button disabled className="ml-3 claim-button disabled">
          {" "}
          {balance}
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
        <div className="has-tooltip">
          <BriefcaseIcon
            className="w-5 h-5 cursor-pointer"
            style={{ color: "#52c41a" }}
            aria-hidden="true"
          />
          <span className={tooltipClass}>{tooltipText}</span>
        </div>
        <button
          className="ml-1 claim-button font-medium hover:text-gray-500"
          onClick={() => claim()}
        >
          {" "}
          {balance}
        </button>
      </div>
    );
  }
}

function MintButton({ userMint }) {
  const tooltipClass =
    "tooltip mt-2 absolute w-1/7 rounded z-10 inline-block shadow-sm text-white p-1 bg-gray-900 opacity-sm duration-300 transition-opacity invisible font-medium py-2 px-3";
  let icon;
  if (userMint) {
    icon = (
      <div className="has-tooltip">
        <EmojiHappyIcon
          className="w-5 h-5"
          style={{ color: "#52c41a" }}
          aria-hidden="true"
        />
        <span className={tooltipClass}>You minted this contract.</span>
      </div>
    );
  } else {
    icon = (
      <div className="has-tooltip">
        <UserIcon className="w-5 h-5" aria-hidden="true" />
        <span className={tooltipClass}>Someone else minted this contract.</span>
      </div>
    );
  }

  return (
    <div className="cursor-default flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg">
      {icon}
      <span className="ml-1 font-medium hover:text-gray-500">
        {userMint ? "Minter" : ""}
      </span>
    </div>
  );
}

function getReferences(references, totalSupplyExact) {
  if (!references) {
    return "No references reported.";
  }

  const referenceList = references.map((reference, index) => {
    const numerator =
      BigInt(reference.amount) * BigInt(10 ** 18) * BigInt(10 ** 5);
    const percent = Number(numerator / BigInt(totalSupplyExact)) / 10 ** 21;

    return (
      <React.Fragment key={`reference${index}`}>
        <br />
        <span>
          ID:{" "}
          <a href={reference.researchIdentifier} target="_blank">
            {optimizeIdentifier(reference.researchIdentifier)}
          </a>
          {", "}
          Percent: {percent}%{" "}
        </span>
      </React.Fragment>
    );
  });

  return (
    <React.Fragment key={`reference-header`}>
      <span>Reference IDs and Percents</span>
      {referenceList}
    </React.Fragment>
  );
}

export default function ResearchCard({
  client,
  myAddress,
  token,
  track,
  setClaimError,
  setClaimSuccess,
  setVerifiedStatus,
}) {
  const {
    address,
    symbol,
    name,
    decimals,
    heldBalance,
    claimBalance,
    researchIdentifier,
    metadata,
    totalSupplyExact,
    transaction,
    minter,
    verified,
  } = token;

  const { getAccessToken } = usePrivy();

  const isMinter =
    minter &&
    minter.address &&
    myAddress &&
    minter.address.toLowerCase() == myAddress.toLowerCase();

  useEffect(() => {
    if (address && verified === undefined) {
      client.getVerifiedStatusFromAddress(address).then((result) => {
        if (result.status == "success") {
          setVerifiedStatus(address, result.verified);
        }
      });
    }
  }, [address, verified]);

  const etherscanAddressUrl = NETWORK.blockExplorer + "address/" + address;
  const references = metadata && metadata.references;
  const referenceNode = getReferences(references, totalSupplyExact);

  const toggleContractVerifyStatus = async () => {
    const minterAddress = minter ? minter.address : null;
    track("ResearchCard", "toggleContractVerifyStatus", address);
    const authToken = await getAccessToken();
    client
      .toggleContractVerifyStatus(
        authToken,
        myAddress,
        address,
        researchIdentifier,
        minterAddress
      )
      .then((resp) => resp.json())
      .then((result) => {
        if (result.status == "success") {
          setVerifiedStatus(address, result.verified);
        }
      });
  };

  const getUrlFromIdentifier = (identifier) => {
    if (identifier.includes("arxiv")) {
      // arxiv:1807.06912
      const arxivId = identifier.split(":")[1];
      return `https://arxiv.org/abs/${arxivId}`;
    }
    return null;
  };
  const researchUrl = getUrlFromIdentifier(researchIdentifier);

  return (
    <li
      key={address}
      className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center justify-between space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
              <Link
                className="token-anchor"
                name="token-anchor"
                to={`/research/${address}`}
                target="_blank"
              >
                {name ? name : "Name of token"}
              </Link>
            </h3>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              <a
                className="etherscan-anchor"
                name="etherscan-anchor"
                href={etherscanAddressUrl}
                target="_blank"
              >
                {symbol ? symbol : "symbol"}
              </a>
            </span>
          </div>
          <div className="flex items-start justify-start space-x-3">
            <Copy text={address} message={"Copy Address"} />
            <AddToWallet
              address={address}
              symbol={symbol}
              decimals={decimals}
            />
            {transaction ? <DeployTransaction transaction={transaction} /> : ""}
          </div>
          <div className="flex items-center justify-between space-x-3 mt-1 text-gray-500 text-sm">
            {researchUrl ? (
              <a href={researchUrl} target="_blank">
                Paper ID: {researchIdentifier}
              </a>
            ) : (
              <span>Paper ID: {researchIdentifier}</span>
            )}
          </div>
          <p className="mt-1 text-gray-500 text-sm">{referenceNode}</p>
        </div>
      </div>
      <div
        className="-mt-px flex divide-x divide-gray-200"
        style={{ marginTop: "auto" }}
      >
        <div className="w-0 flex-1 flex">
          <ConfirmButton
            verified={verified}
            toggleContractVerifyStatus={toggleContractVerifyStatus}
            key="confirm"
          />
        </div>
        {heldBalance ? (
          <div className="-ml-px w-0 flex-1 flex">
            <HeldBalance
              balance={heldBalance}
              addressUrl={etherscanAddressUrl}
              aClass={
                "own-button relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              }
              iconClass={"w-5 h-5 text-blue-400"}
              iconStyle={{ color: "text-blue-400" }}
              key="own"
            />
          </div>
        ) : null}
        {isMinter ? (
          <div className="-ml-px w-0 flex-1 flex">
            <MintButton userMint={isMinter} key="mint" />
          </div>
        ) : null}
        {claimBalance ? (
          <div className="-ml-px w-0 flex-1 flex">
            <ClaimButton
              balance={claimBalance}
              claim={
                client && client.claimUnclaimedForToken
                  ? () => {
                      track("ResearchCard", "claimUnclaimedTokenTry", address);
                      client
                        .claimUnclaimedForToken(address)
                        .then((txns) => {
                          addToWallet(address, symbol, decimals);
                          track(
                            "ResearchCard",
                            "claimUnclaimedTokenSuccess",
                            address
                          );
                          setClaimSuccess({
                            message:
                              "Successfully claimed! We will update here within 15 minutes. Please do not reclaim before then.",
                          });
                        })
                        .catch((error) => {
                          let message = "There was an error claiming";
                          if (error.message) {
                            message += ": " + error.message;
                          } else {
                            message += ".";
                          }
                          track(
                            "ResearchCard",
                            "claimUnclaimedTokenFail",
                            address
                          );
                          track(
                            "ResearchCard",
                            "claimUnclaimedTokenError",
                            message
                          );
                          setClaimError(message);
                        });
                    }
                  : undefined
              }
              key="claim"
            />
          </div>
        ) : null}
      </div>
    </li>
  );
}
