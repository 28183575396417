import React from "react";
import { PresentationChartLineIcon } from "@heroicons/react/outline";

export default function HeldBalance({
  balance,
  addressUrl,
  aClass,
  iconClass,
  iconStyle,
}) {
  return (
    <a target="_blank" href={`${addressUrl}#tokentxns`} className={aClass}>
      <React.Fragment>
        <PresentationChartLineIcon
          className={iconClass}
          style={iconStyle}
          aria-hidden="true"
        />
      </React.Fragment>
      <span className="ml-1 font-medium hover:text-gray-500">{balance}</span>
    </a>
  );
}
