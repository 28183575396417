/*
Page for minting research.
*/

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { BigNumber } from "ethers";
import Select from "react-select";

import { MessageBanner, NetworkDisplayPopup, PageLoading } from "../components";

import { addToWallet } from "../helpers/wallet";
import { ClientContext } from "../helpers/context";

import {
  canRetrieveResearchUrl,
  fixResearchUrlInput,
  isValidEmail,
  isValidHttpUrl,
  removeStopwords,
} from "../helpers/format";

import { NETWORK } from "../constants";

export default function Mint({ track }) {
  const totalSupply = 1e6;
  const authorFrac = 0.8;
  const referenceFrac = 0.2;
  if (authorFrac + referenceFrac != 1) {
    throw new Error("The percents do not add up to 1.");
  }

  const [paperMetadata, setPaperMetadata] = useState({});
  const [numReferenceRows, setNumReferenceRows] = useState(1);
  const [researchUrl, setResearchUrl] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [referenceAddresses, setReferenceAddresses] = useState([""]);
  const [referenceMinters, setReferenceMinters] = useState([""]);
  const [referenceNames, setReferenceNames] = useState([""]);
  const [referencePercents, setReferencePercents] = useState([100]);
  const [referenceUrls, setReferenceUrls] = useState([""]);
  const [wizardStep, setWizardStep] = useState(0);

  const [inputErrors, setInputErrors] = useState([]);
  const [isMessageWindowVisible, setIsMessageWindowVisible] = useState(false);
  const [minting, setMinting] = useState(false);
  const [mintError, setMintError] = useState("");
  const [mintSuccess, setMintSuccess] = useState("");

  const [retrieveError, setRetrieveError] = useState("");

  const { client } = useContext(ClientContext);
  const incorrectNetwork =
    client &&
    client.selectedChainId &&
    parseInt(client.selectedChainId) !== client.targetNetwork.chainId;
  const address = client && client.address;

  const [numAuthorRows, setNumAuthorRows] = useState(1);
  const [authorAddresses, setAuthorAddresses] = useState([""]);
  const [authorEmails, setAuthorEmails] = useState([""]);
  const [authorRoles, setAuthorRoles] = useState([null]);
  const [authorNames, setAuthorNames] = useState([""]);

  useEffect(() => {
    if (wizardStep == 1) {
      nextClick();
    }
  }, [address]);

  const prevResearchUrl = useRef();

  const errorMessageUpdate = () => {
    if (wizardStep === 8) {
      const newInputErrors = getValidationErrors();
      setInputErrors(newInputErrors);
      if (newInputErrors.length > 0) {
        setIsMessageWindowVisible(true);
      } else {
        setIsMessageWindowVisible(false);
      }
    } else {
      setIsMessageWindowVisible(false);
    }
  };

  useEffect(() => {
    errorMessageUpdate();
  }, [wizardStep]);

  const addReference = () => {
    const newNum = numReferenceRows + 1;
    if (newNum > 5) {
      return;
    }
    setNumReferenceRows(newNum);
    setReferenceAddresses([...referenceAddresses, ""]);
    setReferenceUrls([...referenceUrls, ""]);
    setReferenceMinters([...referenceMinters, ""]);
    setReferenceNames([...referenceNames, ""]);
    setReferencePercents([...referencePercents, 0]);
  };

  const removeReference = () => {
    const newNum = numReferenceRows - 1;
    if (newNum < 1) {
      return;
    }
    setNumReferenceRows(newNum);
    setReferenceNames(referenceNames.slice(0, -1));
    setReferenceUrls(referenceUrls.slice(0, -1));
    setReferenceMinters(referenceMinters.slice(0, -1));
    setReferenceAddresses(referenceAddresses.slice(0, -1));
    setReferencePercents(referencePercents.slice(0, -1));
  };

  const addAuthor = () => {
    const newNum = numAuthorRows + 1;
    setNumAuthorRows(newNum);
    setAuthorAddresses([...authorAddresses, ""]);
    setAuthorEmails([...authorEmails, ""]);
    setAuthorRoles([...authorRoles, null]);
    setAuthorNames([...authorNames, ""]);
  };

  const removeAuthor = () => {
    const newNum = numAuthorRows - 1;
    if (newNum < 1) {
      return;
    }
    setNumAuthorRows(newNum);
    setAuthorAddresses(authorAddresses.slice(0, -1));
    setAuthorEmails(authorEmails.slice(0, -1));
    setAuthorRoles(authorRoles.slice(0, -1));
    setAuthorNames(authorNames.slice(0, -1));
  };

  const onInputMetadataChange = (e) => {
    let newMetadata = Object.assign({}, paperMetadata);
    const target = e.target;
    const name = target.name;
    let value = target.value;
    newMetadata[name] = value;
    setPaperMetadata(newMetadata);
  };

  const onInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name.indexOf("referenceUrls") > -1) {
      const index = parseInt(name.slice(-1));
      let newReferenceUrls = [...referenceUrls];
      const fixedValue = fixResearchUrlInput(value);
      newReferenceUrls[index] = fixedValue;
      setReferenceUrls(newReferenceUrls);
      tryReferenceName(fixedValue, (name) => {
        let newReferenceNames = [...referenceNames];
        newReferenceNames[index] = name;
        setReferenceNames(newReferenceNames);
      });
      tryReferenceAddress(fixedValue, (data) => {
        let newReferenceAddresses = [...referenceAddresses];
        let newReferenceMinters = [...referenceMinters];
        if (data && data.verified) {
          newReferenceAddresses[index] = data.address;
          newReferenceMinters[index] = data.minter;
        } else {
          newReferenceAddresses[index] = "";
          newReferenceMinters[index] = "";
        }
        setReferenceAddresses(newReferenceAddresses);
        setReferenceMinters(newReferenceMinters);
      });
    } else if (name.indexOf("referencePercents") > -1) {
      const index = name.slice(-1);
      let newReferencePercents = [...referencePercents];
      newReferencePercents[index] = value;
      setReferencePercents(newReferencePercents);
    } else if (name.indexOf("authorAddresses") > -1) {
      const index = name.slice(-1);
      let newAuthorAddresses = [...authorAddresses];
      newAuthorAddresses[index] = value;
      setAuthorAddresses(newAuthorAddresses);
    } else if (name.indexOf("authorEmails") > -1) {
      const index = name.slice(-1);
      let newAuthorEmails = [...authorEmails];
      newAuthorEmails[index] = value;
      setAuthorEmails(newAuthorEmails);
    } else if (name.indexOf("authorNames") > -1) {
      const index = name.slice(-1);
      let newAuthorNames = [...authorNames];
      newAuthorNames[index] = value;
      setAuthorNames(newAuthorNames);
    } else {
      switch (name) {
        case "researchUrl":
          setResearchUrl(fixResearchUrlInput(value));
          break;
        case "tokenName":
          setTokenName(value);
          break;
        case "tokenSymbol":
          setTokenSymbol(value);
          break;
        default:
          break;
      }
    }

    errorMessageUpdate();
  };

  const setAuthorRole = (index, role) => {
    let newAuthorRoles = [...authorRoles];
    newAuthorRoles[index] = role;
    setAuthorRoles(newAuthorRoles);
  };

  const getValidationErrors = () => {
    let ret = [];
    if (researchUrl.length < 10) {
      ret.push("The canonical URL is required.");
    }
    if (tokenSymbol.length < 3 || tokenSymbol.length > 11) {
      ret.push(
        "The token symbol must be at least three characters in length and at most eleven."
      );
    }
    if (tokenName.length < 3) {
      ret.push("The token name must be at least three characters in length.");
    }
    if (!isValidHttpUrl(researchUrl)) {
      ret.push("The canonical URL is not a valid http url.");
    }

    if (paperMetadata.doi && paperMetadata.doi.length) {
      const re = RegExp('(10[.][0-9]{4,}(?:[.][0-9]+)*/(?:(?![%"#? ])\\S)+)');
      if (!re.test(paperMetadata.doi)) {
        ret.push(
          "The given DOI is not in the correct format. It should look something like '10.1000/xyz1000.a001'."
        );
      }
    }

    let hasFirstAuthor = false;
    for (let i = 0; i < authorNames.length; i++) {
      if (!authorNames[i].length) {
        ret.push(`Author ${i + 1} does not have a name.`);
      }

      if (!authorRoles[i]) {
        ret.push(`Author ${i + 1} does not have a role.`);
      }

      if (!authorAddresses[i].length && !authorEmails[i].length) {
        ret.push(
          `Author ${i + 1} must have at least one of an address or an email.`
        );
      }

      if (authorEmails[i] != "" && !isValidEmail(authorEmails[i])) {
        ret.push(`Author ${i + 1}'s email in invalid.`);
      }

      if (authorAddresses[i] != "" && authorAddresses[i].length !== 42) {
        ret.push(`Author ${i + 1}'s Ethereum address should be 42 in length.`);
      }

      if (authorAddresses[i] != "" && authorAddresses[i].slice(0, 2) != "0x") {
        ret.push(`Author ${i + 1}'s Ethereum address should start with 0x.`);
      }

      if (authorAddresses[i] != "" && authorEmails[i] != "") {
        ret.push(
          `Author ${i + 1} cannot have both an Ethereum address and an email.`
        );
      }

      if (authorRoles[i] && authorRoles[i].value === "first") {
        hasFirstAuthor = true;
      }
    }

    if (!authorNames.length) {
      ret.push("You must have at least one author.");
    } else if (!hasFirstAuthor) {
      ret.push("You must have at least one first author.");
    }

    if (
      !RegExp("[1-2][0-9]{3}-[0-1][0-9]-[0-3][0-9]").test(
        paperMetadata.publishedAt
      )
    ) {
      ret.push(
        "Please enter the date this was published in YYYY-MM-DD format."
      );
    }

    referenceUrls.forEach((url, index) => {
      if (!url || (!isValidHttpUrl(url) && !url.substring(0, 4) === "doi:")) {
        ret.push(`Reference ${index + 1} does not have a valid URL.`);
      }
    });

    let percentSum = 0;
    referencePercents.forEach((percent, index) => {
      percentSum += parseInt(percent);
      if (!percent || parseInt(percent) <= 0 || parseInt(percent) > 100) {
        ret.push(
          `Reference ${index + 1} does not have a valid percent in (0, 100].`
        );
      }
    });

    if (percentSum != 100) {
      ret.push("The sum of reference percents is not equal to 100.");
    }

    if (!paperMetadata.abstract) {
      ret.push("The abstract is required.");
    }

    return ret;
  };

  const onSubmit = (e) => {
    track("Mint", "submitMinting", "try");
    e.preventDefault();

    (async () => {
      const newInputErrors = getValidationErrors();
      setInputErrors(newInputErrors);
      if (newInputErrors.length > 0) {
        setIsMessageWindowVisible(true);
        track("Mint", "submitMinting", "hasValidationErrors");
        return;
      }

      try {
        setMinting(true);
        setIsMessageWindowVisible(false);
        setMintSuccess("");
        setMintError("");

        const _decimals = 18;
        const decimals = BigNumber.from(10).pow(_decimals);
        const supply = BigNumber.from(totalSupply);
        const cap = decimals.mul(totalSupply);
        const authorShare = supply
          .mul(BigNumber.from(authorFrac * 10 ** 8))
          .div(BigNumber.from(10 ** 8));

        // Right here, we put in the address of the researchContractDistributions.
        // This is to any contracts we can complete. The remaining amount is
        // distributed to our Multisig contract for delivery when they verify.
        let { multisigDirect, researchContractDistributions, references } =
          client.getReferenceDistributions(
            referenceUrls,
            referenceAddresses,
            referenceMinters,
            referencePercents,
            referenceFrac,
            supply,
            decimals
          );

        let { firstAuthors, middleAuthors, piAuthors } =
          client.getAuthorDistributions(
            authorNames,
            authorEmails,
            authorAddresses,
            authorRoles,
            decimals,
            authorShare
          );

        const authors = [...firstAuthors, ...middleAuthors, ...piAuthors];
        let authorsWithAddresses = await client.getAuthorsWithAddresses(
          authors,
          () => {
            track("Mint", "submitMinting", "privyAddressError");
            const errorMessage =
              "Error getting privy addresses for unknown authors. Please report this to us.";
            setMintError(errorMessage);
            setMintSuccess("");
          }
        );

        const RESEARCH_PORTFOLIO_GNOSIS_ADDRESS =
          process.env.RESEARCH_PORTFOLIO_GNOSIS_ADDRESS;

        const distributions =
          await client.getDistributionsFromTokenDistributions(
            researchContractDistributions
          );

        const directs = client.getDirects(
          authorsWithAddresses,
          researchContractDistributions,
          cap,
          multisigDirect,
          RESEARCH_PORTFOLIO_GNOSIS_ADDRESS
        );

        track("Mint", "submitMinting", "createMerkleDistributedToken");
        const { txn, token } = await client.createMerkleDistributedToken({
          blockNumber: BigNumber.from(0), // TODO to avoid race condition this should be accurate
          name: tokenName,
          symbol: tokenSymbol,
          firstAuthors,
          middleAuthors,
          piAuthors,
          directs,
          distributions,
          researchIdentifier: researchUrl,
          references,
          paperMetadata: Object.assign({}, paperMetadata, {
            title: paperMetadata.title || tokenName,
            publishedAt:
              paperMetadata.publishedAt &&
              paperMetadata.publishedAt.indexOf("T") == -1
                ? (paperMetadata.publishedAt =
                    paperMetadata.publishedAt + "T00:00:00Z")
                : paperMetadata.publishedAt,
          }),
          RESEARCH_PORTFOLIO_GNOSIS_ADDRESS,
        });

        const transactionHash = txn.hash;

        // Pop up a confirmation to add to the wallet.
        addToWallet(token, tokenSymbol, _decimals);

        // Clear out errors.
        setMintError("");
        // Set the success info and move the wizard to the post step.
        setMintSuccess({ transactionHash, token });
        setWizardStep(wizardStep + 1);
        track("Mint", "submitMinting", "success");
      } catch (e) {
        console.error(e);
        console.log(e);
        // TODO: Fix this / add a link instead of "please visit".
        let message = `Hey, something went wrong either with minting or afterward on our end. Please check your wallet. If it completed a transaction, then it's on us and we'll fix this asap: ${e.message}`;

        if (
          (e.data &&
            e.data.message &&
            e.data.message.indexOf("create2") > -1) ||
          (e.message && e.message.indexOf("create2") > -1)
        ) {
          message =
            "Hey, are you sure that you haven't already minted this paper?";
        } else if (e.code == 4001) {
          message = "Hey, it looks like you rejected the minting transaction.";
        }
        track("Mint", "submitMinting", "error");
        track("Mint", "submitMintingError", message);
        setMintError({ message });
        setMintSuccess("");
      } finally {
        setIsMessageWindowVisible(true);
        setMinting(false);
      }
    })();
  };

  // Try to get the name of this reference paper.
  async function tryReferenceName(referenceUrl, callback) {
    if (canRetrieveResearchUrl(referenceUrl)) {
      track("Mint", "retrieveResearchName", referenceUrl);
      await client
        .getPaperMetadata(referenceUrl)
        .then((metadata) => {
          // metadata has {abstract, arxivId, authors: [{name, email, uri}], doi, pdfUrl, publishedAt, title}
          callback(metadata["title"]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      callback("");
    }
  }

  // Try to get the name of this reference paper.
  async function tryReferenceAddress(referenceUrl, callback) {
    client
      .getAddressFromResearchIdentifier(referenceUrl)
      .then((data) => {
        // data has {address: string, verified: bool}
        callback(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function tryRetrieve(researchUrl) {
    if (canRetrieveResearchUrl(researchUrl)) {
      track("Mint", "retrieveResearchUrl", researchUrl);
      await client
        .getPaperMetadata(researchUrl)
        .then((metadata) => {
          // metadata has {abstract, arxivId, authors: [{name, email, uri}], doi, pdfUrl, publishedAt, title}
          const tokenName = metadata["title"].replace(/(?:\r\n|\r|\n)/g, "");
          setPaperMetadata(metadata);
          setTokenName(tokenName);
          setIsMessageWindowVisible(false);
          setRetrieveError("");
          // if (wizardStep === 3) {
          //   setWizardStep(wizardStep + 1);
          // }

          if (tokenName) {
            const justWords = tokenName
              .replace(/[^a-zA-Z\s]/g, "")
              .toLowerCase();
            let tempSymbol = removeStopwords(justWords);
            if (!tempSymbol) {
              tempSymbol = justWords;
            }
            tempSymbol = tempSymbol
              .split(" ")
              .filter((f) => f)
              .map((f) => f[0].toUpperCase())
              .join("")
              .slice(0, 11);
            setTokenSymbol(tempSymbol);
          }
        })
        .catch((error) => {
          track("Mint", "retrieveResearchUrlError", researchUrl);
          console.log(error);
          setPaperMetadata({});
          setTokenName("");
          setIsMessageWindowVisible(true);
          setRetrieveError(
            "Error retrieving the paper. Is that url correct for Arxiv, Biorxiv, or Medrxiv?"
          );
        });
    }
  }

  const resetInputInfo = () => {
    setNumAuthorRows(1);
    setNumReferenceRows(1);
    setResearchUrl("");
    setTokenName("");
    setTokenSymbol("");
    setPaperMetadata({});
    setReferenceAddresses([""]);
    setReferenceMinters([""]);
    setReferenceNames([""]);
    setReferencePercents([100]);
    setReferenceUrls([""]);
    setAuthorAddresses([""]);
    setAuthorEmails([""]);
    setAuthorRoles([""]);
    setAuthorNames([""]);
  };

  let topBannerMessage;
  if (mintError && isMessageWindowVisible) {
    topBannerMessage = <MintFail info={mintError} />;
  } else if (retrieveError && isMessageWindowVisible) {
    topBannerMessage = <RetrieveError info={retrieveError} />;
  } else if (minting) {
    topBannerMessage = <PageLoading />;
  } else if (inputErrors && inputErrors.length && isMessageWindowVisible) {
    topBannerMessage = <InputErrors errorList={inputErrors} />;
  } else {
    topBannerMessage = null;
  }

  const tokenNameAndSymbolInput = () => {
    return (
      <React.Fragment>
        <div className="col-span-6">
          <label
            htmlFor="tokenName"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            Token Name (required)
          </label>
          <div className="flex rounded border border-slate-200 shadow-sm">
            <input
              type="text"
              onChange={onInputChange}
              placeholder="Neural Audio Synthesis of Musical Notes with WaveNet Autoencoders"
              name="tokenName"
              id="tokenName"
              value={tokenName}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label
            htmlFor="tokenSymbol"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            Token Symbol (required)
          </label>
          <div className="flex rounded border border-slate-200 shadow-sm">
            <input
              type="text"
              onChange={onInputChange}
              placeholder="e.g. NSYNTH"
              name="tokenSymbol"
              id="tokenSymbol"
              value={tokenSymbol}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const canonicalUrlInput = () => {
    return (
      <React.Fragment>
        <div className="col-span-7">
          <label
            htmlFor="researchUrl"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            Canonical URL (required)
          </label>
          <div className="flex rounded border border-slate-200 shadow-sm">
            <input
              type="url"
              onChange={onInputChange}
              placeholder="https://arxiv.org/abs/1704.01279"
              name="researchUrl"
              id="researchUrl"
              value={researchUrl}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>
        <div className="relative col-span-1">
          <button
            onClick={confirmClick}
            className="w-full absolute bottom-0 h-11 bg-cyan-600 rounded justify-center items-center inline-flex text-white text-base font-semibold leading-normal"
          >
            Confirm
          </button>
        </div>
      </React.Fragment>
    );
  };

  const stepZero = () => {
    // wizardStep = 0 is the "here is why you should do this and why this persists if our service goes away."
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Mint your Paper
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          This wizard guides you through minting a paper on the Ethereum
          blockchain. Afterwards, your paper will have 1 million tokens.
          <br />
          <br />
          Those tokens will be available forever and anyone can build on them or
          send other tokens to their holders. This is meant to spur innovation
          in research funding, reviewing, impact evaluation, and publication.
          <br />
          <br />
          If you have already minted a paper and want it verified, please
          contact us at support@researchportfolio.co.
        </div>
        {navigationButtons(true)}
      </div>
    );
  };

  const stepOne = () => {
    // wizardStep = 1 is the "get a wallet please. here is why and instructions on how to do it."
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Your Wallet
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          Research Portfolio uses your Web3 wallet for minting papers and
          claiming tokens. Install or link a wallet by clicking the blue button
          in the top right, then following instructions to either sign in with
          an existing wallet or creating a new one.
          <br />
          <br />
          If you were notified that you have received tokens, you can claim them
          by logging in with that email address after clicking the same blue
          button. Afterwards, your tokens will be waiting to claim on the
          "Explore Research" page.
        </div>
        {navigationButtons(true)}
      </div>
    );
  };

  const stepTwo = () => {
    // wizardStep = 2 is the "you have a wallet! now let's get on the right network."
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Connect to the right chain
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          Every blockchain has its own network. Ethereum's network is Mainnet
          and Polygon's is Matic.
          <br />
          <br />
          Research Portfolio runs on {client.targetNetwork.name}. Please switch
          by clicking the button below.
          <br />
          <div className="text-center" style={{ marginTop: "30px" }}>
            <NetworkDisplayPopup />
          </div>
        </div>
        {navigationButtons(true)}
      </div>
    );
  };

  const stepThree = () => {
    // wizardStep = 3 is "put in the canonical DOI or URL."
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Identifying your paper
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          What is the canonical url identifier for your paper? This is important
          so that we can properly attribute tokens from reference papers to you.
          <br />
          <br />
          If you use Arxiv, Biorxiv, or Medrxiv, our system will retrieve paper
          info automatically. If that is not the canonical url, then please use
          whatever source you would consider most trustworthy.
          <div
            key="canonicalInfo"
            className="mt-6 grid grid-cols-8 gap-x-4 border-0"
            style={{ marginTop: "30px" }}
          >
            {canonicalUrlInput()}
          </div>
        </div>
        {navigationButtons(true)}
      </div>
    );
  };

  const stepFour = () => {
    // wizardStep = 4 is "Put in the Token Name and Token Symbol"
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Identifying your Token
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          What name and symbol would you like your token to have?
          <br />
          <br />
          The name should be the paper's name. The symbol is how people refer to
          your token and should be between 3 and 11 characters. A good choice is
          the paper's nickname or the nickname of a method in the paper.
          <br />
          <br />
          If you submitted an Arxiv, Biorxiv, or Medrxiv link, we've already
          input the name and a placeholder symbol.
          <div
            key="inputInfo"
            className="border-0 grid gap-y-6 gap-x-4 grid-cols-8 border-0"
            style={{ marginTop: "30px" }}
          >
            {tokenNameAndSymbolInput()}

            <div className="col-span-8">{navigationButtons(true)}</div>
            <div className="col-span-8 border-t-2" />

            {canonicalUrlInput()}
          </div>
        </div>
      </div>
    );
  };

  const stepFive = () => {
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Receiving your tokens
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          To distribute the author tokens directly to authors, we need to know
          each author's Ethereum address or email, along with their role in the
          paper. If you don't know an address for an author or they don't have
          one, we will make a special wallet for them that only they can access
          via their email (using{" "}
          <a className="underline" href="https://www.privy.io">
            Privy
          </a>
          ) .
          <br />
          <br />
          Their name helps identify their token allocation and won't be used for
          anything else. Their email is only used to grant them access to their
          allocation and is only necessary if you don't already know their
          Ethereum address.
          <br />
          <br />
          The fixed token distribution is:
          <div className="ml-3">
            <ul className="mt-1">
              <li>First authors evenly split 70% of the tokens.</li>
              <li>The principal investigators split 20% of the tokens.</li>
              <li>The remaining authors split 10% of the tokens.</li>
            </ul>
          </div>
          <div
            key="inputInfo"
            className="border-0 grid gap-y-6 gap-x-4 grid-cols-8 border-0"
            style={{ marginTop: "30px" }}
          >
            <AuthorRows
              addAuthor={addAuthor}
              removeAuthor={removeAuthor}
              onInputChange={onInputChange}
              numAuthors={numAuthorRows}
              authorNames={authorNames}
              authorEmails={authorEmails}
              authorRoles={authorRoles}
              authorAddresses={authorAddresses}
              setAuthorRole={setAuthorRole}
            />

            <div className="col-span-8">{navigationButtons(true)}</div>

            <div className="col-span-8 border-t-2" />

            {tokenNameAndSymbolInput()}

            {canonicalUrlInput()}
          </div>
        </div>
      </div>
    );
  };

  const stepSix = () => {
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Almost done
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          Please insert the abstract, publish date, and DOI. This helps the
          world be sure that it's the right paper.
          <br />
          <br />
          The DOI is the paper's unique digital object identifier. It helps us
          disambiguate. Insert the DOI in the{" "}
          <a href="https://en.wikipedia.org/wiki/Digital_object_identifier#Nomenclature_and_syntax">
            traditional format
          </a>
          , e.g. 10.1000/182. Arxiv displays DOIs just underneath the comments
          section. Biorxiv and Medrxiv displays them just under the author list.
          <div
            key="inputInfo"
            className="border-0 grid gap-y-6 gap-x-4 grid-cols-8 border-0"
            style={{ marginTop: "30px" }}
          >
            <Metadata
              metadata={paperMetadata}
              onInputChange={onInputMetadataChange}
            />

            <div className="col-span-8">{navigationButtons(true)}</div>
            <div className="col-span-8 border-t-2" />

            <AuthorRows
              addAuthor={addAuthor}
              removeAuthor={removeAuthor}
              onInputChange={onInputChange}
              numAuthors={numAuthorRows}
              authorNames={authorNames}
              authorEmails={authorEmails}
              authorRoles={authorRoles}
              authorAddresses={authorAddresses}
              setAuthorRole={setAuthorRole}
            />

            {tokenNameAndSymbolInput()}
            {canonicalUrlInput()}
          </div>
        </div>
      </div>
    );
  };

  const stepSeven = () => {
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Final step!
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          The final step before reviewing is listing your paper's most important
          references (min 1, max 5), along with their importance weight, which
          should sum to 100. These references are the works that greatly
          influenced your work or without which it would not have been possible
          to complete. We prefer Arxiv or DOI when possible.
          <br />
          <br />
          After minting your paper, 20% of the tokens are distributed
          proportionately to the people who own the tokens of those works. As
          your work disseminates, so too will it receive tokens from works that
          it influenced. In this way, research respects and supports the
          shoulders on which it stands.
          <div
            key="inputInfo"
            className="border-0 grid gap-y-6 gap-x-4 grid-cols-8 border-0"
            style={{ marginTop: "30px" }}
          >
            <ReferenceRows
              numReferences={numReferenceRows}
              referenceAddresses={referenceAddresses}
              referenceUrls={referenceUrls}
              referencePercents={referencePercents}
              referenceNames={referenceNames}
              onInputChange={onInputChange}
              addReference={addReference}
              removeReference={removeReference}
            />

            <div className="col-span-8">{navigationButtons(true)}</div>
            <div className="col-span-8 border-t-2" />

            <Metadata
              metadata={paperMetadata}
              onInputChange={onInputMetadataChange}
            />

            <AuthorRows
              addAuthor={addAuthor}
              removeAuthor={removeAuthor}
              onInputChange={onInputChange}
              numAuthors={numAuthorRows}
              authorNames={authorNames}
              authorEmails={authorEmails}
              authorRoles={authorRoles}
              authorAddresses={authorAddresses}
              setAuthorRole={setAuthorRole}
            />

            {tokenNameAndSymbolInput()}
            {canonicalUrlInput()}
          </div>
        </div>
      </div>
    );
  };

  const stepEight = () => {
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          {inputErrors.length > 0
            ? "Almost ready to submit"
            : "Please confirm and then submit"}
        </div>

        <div className="text-neutral-900 text-base font-normal leading-relaxed">
          {inputErrors.length === 0 && (
            <React.Fragment>
              Check all the info below. If it looks good, then mint by clicking
              submit! After you hit submit, a confirmation pop-up from your
              wallet will appear. Confirming completes the process.
              <br />
              <br />
              It takes ~10-30 seconds for the blockchain to confirm the
              transaction. Stay here while that's happening. If it's successful,
              another pop-up will appear asking to confirm tracking your token
              appear in Metamask. Say yes to that. If it's not successful, we'll
              show the error here. Please let us know!
            </React.Fragment>
          )}

          <form
            key="form"
            onSubmit={onSubmit}
            className="space-y-10"
            style={{ marginTop: "30px" }}
          >
            <div
              key="inputInfo"
              className="border-0 grid gap-y-6 gap-x-4 grid-cols-8 border-0"
            >
              {canonicalUrlInput()}

              {tokenNameAndSymbolInput()}

              <AuthorRows
                disabled={minting}
                addAuthor={addAuthor}
                removeAuthor={removeAuthor}
                onInputChange={onInputChange}
                numAuthors={numAuthorRows}
                authorNames={authorNames}
                authorEmails={authorEmails}
                authorRoles={authorRoles}
                authorAddresses={authorAddresses}
                setAuthorRole={setAuthorRole}
              />

              <ReferenceRows
                disabled={minting}
                numReferences={numReferenceRows}
                referenceAddresses={referenceAddresses}
                referenceUrls={referenceUrls}
                referencePercents={referencePercents}
                referenceNames={referenceNames}
                onInputChange={onInputChange}
                addReference={addReference}
                removeReference={removeReference}
              />

              <Metadata
                disabled={minting}
                metadata={paperMetadata}
                onInputChange={onInputMetadataChange}
              />

              <div className="col-span-4 sm:col-span-3">
                <div className="py-5 flex flex-row gap-x-5 justify-end">
                  <div className="flex h-10">{prevButton()}</div>
                  <SubmitButton
                    address={address}
                    disabled={minting}
                    spinner={minting}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const stepNine = () => {
    return (
      <div>
        <div className="text-zinc-900 text-2xl font-semibold leading-[54px]">
          Success! To verify this paper as the correct one, please contact us at
          support@researchportfolio.co.
        </div>
        <div key="tips" className="mt-8 space-y-2">
          <MintSuccess info={mintSuccess} researchUrl={researchUrl} />
        </div>
        {navigationButtons(true)}
      </div>
    );
  };

  const wizard = (wizardStep) => {
    if (wizardStep === 0) {
      return stepZero();
    } else if (wizardStep === 1) {
      return stepOne();
    } else if (wizardStep == 2) {
      return stepTwo();
    } else if (wizardStep == 3) {
      return stepThree();
    } else if (wizardStep == 4) {
      // Do both 3 and 4 to show it.
      return stepFour();
    } else if (wizardStep == 5) {
      // wizardStep = 5 is "Put in the Ethereum address to send the tokens."
      return stepFive();
    } else if (wizardStep == 6) {
      // wizardStep = 6 is "Abstract, DOI, Publish Date, and Authors.""
      return stepSix();
    } else if (wizardStep == 7) {
      // wizardStep = 7 is "Final Step: The References."
      return stepSeven();
    } else if (wizardStep == 8) {
      // wizardStep = 8 is "Show everything; Submit button at top."
      // After submit is completed, we clear everything and show a) txn link, b) link to the paper page, and
      // c) instructions for confirming the hash.
      // If there was an error, then we show that.
      return stepEight();
    } else if (wizardStep === 9) {
      // wizardStep = 9 is "Success!"
      return stepNine();
    }
  };

  const confirmClick = () => {
    if (
      prevResearchUrl.current != researchUrl &&
      canRetrieveResearchUrl(researchUrl)
    ) {
      const tempResearchUrl = researchUrl;
      resetInputInfo();
      setResearchUrl(tempResearchUrl);
      tryRetrieve(tempResearchUrl);
    }
    prevResearchUrl.current = researchUrl;

    if (wizardStep == 3) {
      nextClick();
    }
  };
  const nextClick = () => {
    let newWizardStep = wizardStep;
    if (newWizardStep == 0) {
      if (address && !incorrectNetwork) {
        newWizardStep += 3;
      } else if (address) {
        newWizardStep += 2;
      } else {
        newWizardStep += 1;
      }
    } else if (newWizardStep == 1) {
      if (address && !incorrectNetwork) {
        newWizardStep += 2;
      } else if (address) {
        newWizardStep += 1;
      }
    } else if (newWizardStep == 2) {
      if (address && !incorrectNetwork) {
        newWizardStep += 1;
      }
    } else if (newWizardStep < 8) {
      newWizardStep += 1;
    }

    setWizardStep(newWizardStep);
  };

  const prevClick = () => {
    let newWizardStep = wizardStep;
    if (wizardStep == 9) {
      // Start over from the beginning.
      newWizardStep = 3;
      // Clear all the current information.  const [paperMetadata, setPaperMetadata] = useState({});
      resetInputInfo();
    } else if (newWizardStep == 3) {
      if (address && !incorrectNetwork) {
        newWizardStep -= 3;
      } else if (address) {
        newWizardStep -= 1;
      } else {
        newWizardStep -= 2;
      }
    } else if (newWizardStep > 0) {
      newWizardStep -= 1;
    }
    setWizardStep(newWizardStep);
  };

  const prevButton = (extraStyle) => {
    if (wizardStep === 0) {
      return null;
    }

    let buttonStyle =
      "h-10 p-3.5 bg-zinc-900 bg-opacity-90 rounded border border-slate-200 justify-center items-center gap-2.5 text-white text-base font-semibold leading-normal inline-flex";
    if (extraStyle) {
      buttonStyle += " " + extraStyle;
    }

    return (
      <button className={buttonStyle} onClick={prevClick}>
        {wizardStep < 9 ? "Previous" : "Mint another!"}
      </button>
    );
  };

  const nextButton = () => {
    const className =
      "h-10 p-3.5 bg-blue-600 rounded justify-center items-center gap-2.5 inline-flex text-white text-base font-semibold leading-normal right-0";
    switch (wizardStep) {
      case 0:
        return (
          <button className={className} onClick={nextClick}>
            Continue
          </button>
        );
      case 1:
        return null;
      case 3:
        return null;
      case 8:
        return (
          <button className={className} onClick={nextClick}>
            Complete
          </button>
        );
      default:
        return (
          <button className={className} onClick={nextClick}>
            Continue
          </button>
        );
    }
  };

  function navigationButtons(splitButtons) {
    let classNames =
      "flex inline-flex items-center py-4 my-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500";
    if (splitButtons) {
      classNames = "w-full " + classNames;
    }
    if (wizardStep === 0) {
      classNames += " justify-end";
    } else if (wizardStep === 8) {
      classNames += " justify-start";
    } else {
      classNames += " justify-between";
    }

    return (
      <div className={classNames}>
        {prevButton()}
        {nextButton()}
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-10">
      <div className="mx-auto max-w-3xl">
        <div className="content-center">
          {topBannerMessage}
          {wizardStep < 8 ? (
            <div className="w-[640px] text-gray-500 text-[11px] font-semibold uppercase leading-normal tracking-tight">
              Step {wizardStep + 1}/9
            </div>
          ) : null}

          {wizard(wizardStep)}
        </div>
      </div>
    </div>
  );
}

function InputErrors({ errorList }) {
  const introString =
    errorList.length === 1
      ? "One small change por favor:"
      : "A few (" +
        errorList.length +
        ") changes are necessary before we can mint:";
  const color = "red";
  const message = (
    <ul role="list" className="list-disc pl-5 space-y-1">
      {errorList.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
  );
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

function MintSuccess({ info, researchUrl }) {
  const { transactionHash, token } = info;
  const introString = "You minted your paper!";
  const color = "green";
  const link = `/research/${token}`;

  const message = (
    <React.Fragment>
      <p className="text-sm">
        See the{" "}
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          href={`${NETWORK.blockExplorer}tx/${transactionHash}`}
        >
          transaction
        </a>{" "}
        on etherscan. Visit the paper's page{" "}
        <Link
          id="artifactLink"
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          to={link}
        >
          here
        </Link>
        .
      </p>
    </React.Fragment>
  );
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

function MintFail({ info }) {
  const introString = "Mint Fail!";
  const color = "yellow";
  const message = (
    <p id="mintError" className="text-sm">
      {info.message}
    </p>
  );
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

function RetrieveError({ info }) {
  const introString = "We failed to retrieve from Arxiv.";
  const color = "yellow";
  const message = <p className="text-sm">{info}</p>;
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

function SubmitButton({ address, disabled, spinner }) {
  if (address) {
    const buttonClassNames =
      "bg-blue-600 h-10 p-3.5 bg-opacity-90 rounded border border-slate-200 justify-center items-center gap-2.5 text-white text-base font-semibold leading-normal inline-flex";

    return (
      <button
        id="submitForm"
        type="submit"
        disabled={disabled}
        className={buttonClassNames}
      >
        {spinner ? "Minting" : "Submit"}
      </button>
    );
  } else {
    return (
      <button
        type="submit"
        disabled
        className="disabled cursor-not-allowed ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
      >
        Submit
      </button>
    );
  }
}

function Metadata({ disabled, onInputChange, metadata }) {
  const { abstract, doi, publishedAt } = metadata;
  const date = publishedAt ? publishedAt.split("T")[0] : "";
  const maybeDOI = doi ? doi : "";
  const abstractWithoutNewlines = abstract ? abstract.replace(/\n/g, " ") : "";

  const MIN_TEXTAREA_HEIGHT = 60;
  const textAreaRef = React.useRef(null);
  React.useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(
      textAreaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [abstractWithoutNewlines]);

  return (
    <React.Fragment>
      <div className="col-span-8">
        <label
          htmlFor="abstract"
          className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
        >
          Abstract (required)
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <textarea
            type="text"
            name="abstract"
            id="abstract"
            ref={textAreaRef}
            style={{ minHeight: MIN_TEXTAREA_HEIGHT, resize: "none" }}
            onChange={onInputChange}
            value={abstractWithoutNewlines}
            disabled={disabled}
            className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
          />
        </div>
      </div>
      <div className="col-span-2">
        <label
          htmlFor="doi"
          className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
        >
          DOI
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="text"
            name="doi"
            id="doi"
            onChange={onInputChange}
            value={maybeDOI}
            disabled={disabled}
            className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
          />
        </div>
      </div>
      <div className="col-span-2 sm:col-span-3">
        <label
          htmlFor="publishedAt"
          className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
        >
          Published YYYY-MM-DD (required)
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <input
            type="text"
            name="publishedAt"
            id="publishedAt"
            onChange={onInputChange}
            value={date}
            disabled={disabled}
            className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

const getReferenceAddressInfo = (referenceAddress) => {
  const maybeReferenceAddress = referenceAddress
    ? referenceAddress
    : "Unverified. We will mint to our Safe until verified.";
  return (
    <div className="mt-1 flex rounded-md shadow-sm">
      <span className="text-sm font-medium text-gray-700">
        {maybeReferenceAddress}
      </span>
    </div>
  );
};

function ReferenceRows({
  addReference,
  disabled,
  onInputChange,
  numReferences,
  referenceAddresses,
  referenceUrls,
  referenceNames,
  referencePercents,
  removeReference,
}) {
  const rows = [];
  for (let i = 0; i < numReferences; i++) {
    let idMessage = `Canonical Identifier`;
    let coinMessage = `Weight`;
    if (i == 0) {
      coinMessage += " (req)";
      idMessage += " (req)";
    }
    const referenceName = referenceNames[i];
    const referenceAddress = referenceAddresses[i];
    rows.push(
      <React.Fragment key={`reference${i}`}>
        <div key={`referenceHeaders${i}`} className="col-span-8">
          <div className="text-zinc-900 text-xl font-semibold">
            Reference #{i + 1}
          </div>
        </div>

        <div key={`referenceUrl${i}`} className="col-span-6">
          <div className="flex">
            <label
              htmlFor="referenceUrl"
              className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
            >
              {idMessage}
            </label>
          </div>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="url"
              placeholder="https://arxiv.org/abs/1609.03499v1"
              name={`referenceUrls${i}`}
              id={`referenceUrls${i}`}
              value={referenceUrls[i]}
              onChange={onInputChange}
              disabled={disabled}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
          {referenceName ? (
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="text-sm font-medium text-gray-700">
                {referenceName}
              </span>
            </div>
          ) : null}
          {getReferenceAddressInfo(referenceAddress)}
        </div>

        <div key={`referencePercent${i}`} className="col-span-2">
          <label
            htmlFor="referencePercent"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            {coinMessage}
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="number"
              placeholder="100"
              min="1"
              max="100"
              name={`referencePercents${i}`}
              id={`referencePercents${i}`}
              value={referencePercents[i]}
              onChange={onInputChange}
              disabled={disabled}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (!disabled) {
    const toggleReferenceButtons = (
      <div key="toggleReferences" className="col-span-8">
        <button
          type="button"
          id="addReferenceButton"
          onClick={() => addReference()}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {numReferences < 5 ? "Add reference row." : "Reached max references."}
        </button>
        {numReferences == 1 ? null : (
          <button
            type="button"
            id="removeReferenceButton"
            onClick={() => removeReference()}
            className="inline-flex items-center sm:ml-2.5 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Remove reference row.
          </button>
        )}
      </div>
    );

    rows.push(toggleReferenceButtons);
  }

  return rows;
}

function AuthorRows({
  addAuthor,
  removeAuthor,
  disabled,
  onInputChange,
  numAuthors,
  authorNames,
  authorAddresses,
  authorRoles,
  authorEmails,
  setAuthorRole,
}) {
  const roleOptions = [
    { value: "first", label: "First Author" },
    { value: "middle", label: "Middle Author" },
    { value: "pi", label: "Principal Investigator" },
  ];
  const rows = [];
  for (let i = 0; i < numAuthors; i++) {
    rows.push(
      <React.Fragment key={`author${i}`}>
        <div key={`authorHeader${i}`} className="col-span-8">
          <div className="text-zinc-900 text-xl font-semibold">
            Author #{i + 1}
          </div>
        </div>
        <div key={`authorName${i}`} className="col-span-4">
          <div className="flex">
            <label
              htmlFor="authorName"
              className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
            >
              Name (required)
            </label>
          </div>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name={`authorNames${i}`}
              id={`authorNames${i}`}
              value={authorNames[i]}
              onChange={onInputChange}
              disabled={disabled}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>

        <div key={`authorRole${i}`} className="col-span-4">
          <label
            htmlFor="authorAddress"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            Role (required)
          </label>
          <Select
            options={roleOptions}
            defaultValue={authorRoles[i]}
            onChange={(option) => setAuthorRole(i, option)}
          />
        </div>

        <div key={`authorEmail${i}`} className="col-span-4">
          <label
            htmlFor="authorEmail"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            Email
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name={`authorEmails${i}`}
              id={`authorEmails${i}`}
              value={authorEmails[i]}
              onChange={onInputChange}
              disabled={disabled}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>

        <div key={`authorAddresses${i}`} className="col-span-4">
          <label
            htmlFor="authorAddress"
            className="block text-neutral-900 text-[11px] font-semibold leading-normal tracking-tight"
          >
            Ethereum Address
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <input
              type="text"
              name={`authorAddresses${i}`}
              id={`authorAddresses${i}`}
              value={authorAddresses[i]}
              onChange={onInputChange}
              disabled={disabled}
              className="flex focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 text-neutral-900 text-base font-normal rounded-none"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (!disabled) {
    const toggleAuthorButtons = (
      <div key="toggleAuthors" className="col-span-8">
        <button
          type="button"
          id="addAuthorButton"
          onClick={() => addAuthor()}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add author row.
        </button>
        {numAuthors == 1 ? null : (
          <button
            type="button"
            id="removeAuthorButton"
            onClick={() => removeAuthor()}
            className="inline-flex items-center sm:ml-2.5 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Remove author row.
          </button>
        )}
      </div>
    );

    rows.push(toggleAuthorButtons);
  }

  return rows;
}
