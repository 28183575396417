import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import AnalyticsTracker from "./AnalyticsTracker";
import { NetworkDisplayPopup } from "./";

export default function Header({ account, hasAnalytics }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  const useAccount =
    location &&
    (location.pathname === "/mint" ||
      location.pathname === "/amaranth-prize" ||
      location.pathname.startsWith("/research"));
  const gaTracker = hasAnalytics ? AnalyticsTracker("Header") : null;

  const track = (href) => {
    if (hasAnalytics) {
      gaTracker.trackEvent("click", href);
    }
  };

  const mobileTrack = (href) => {
    if (hasAnalytics) {
      gaTracker.trackEvent("mobileClick", href);
    }
  };

  const navigation = [
    { type: "link", name: "Manifesto", href: "/manifesto" },
    { type: "link", name: "FAQs", href: "/faq" },
    { type: "link", name: "Mint Research", href: "/mint" },
    { type: "link", name: "Explore Research", href: "/research" },
  ];

  return (
    <Popover as="header" className="relative">
      <NetworkDisplayPopup />
      {/* Hamburger Menu Icon */}
      <button
        onClick={toggleMenu}
        className="md:hidden absolute right-4 z-50 pt-6 focus:outline-none"
      >
        {isMenuOpen ? (
          <XIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
        ) : (
          <MenuIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
        )}
      </button>

      <div className="pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between"
          aria-label="Global"
        >
          <div className="flex items-center justify-between flex-1">
            <div className="flex items-center w-full md:w-auto sm:w-auto">
              <Link to={"/"} onClick={() => track("home")}>
                <span className="header-logo">Research Portfolio</span>
              </Link>
            </div>
            <div className="space-x-6 flex ml-10 xs:mr-5 md:flex hidden">
              {navigation.map((item) => {
                return (
                  <Link
                    key={item.href}
                    to={item.href}
                    onClick={() => track(item.href)}
                  >
                    <div className="text-base sm:text-lg xs:text-lg font-medium text-gray-600 hover:text-black min-w-max header-link">
                      {item.name}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          {useAccount ? (
            <div className="md:flex md:items-center md:space-x-6 sm:flex sm:items-center sm:space-x-6 sm:ml-5 sm:mr-12 md:mr-0">
              {account}
            </div>
          ) : null}
        </nav>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white w-1/4 fixed top-16 right-0 p-4 py-0 z-10 shadow text-right">
          <div className="space-y-2">
            {navigation.map((item) => (
              <Link
                key={item.href}
                to={item.href}
                onClick={() => {
                  mobileTrack(item.href);
                  setIsMenuOpen(false); // Close the menu after clicking a link
                }}
                className="block text-base font-medium text-gray-600 hover:text-black"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </Popover>
  );
}
