import { BriefcaseIcon } from "@heroicons/react/outline";

export default function ClaimBalance({ address, balance, client, callback }) {
  const onClick = () => {
    if (!client) {
      return;
    }

    client
      .claimUnclaimedForToken(address)
      .then((txns) => {
        callback({ success: true });
      })
      .catch((error) => {
        callback({ success: false });
      });
  };

  const icon = (
    <BriefcaseIcon
      className="w-4 h-4"
      style={{ color: "#52c41a" }}
      aria-hidden="true"
    />
  );
  return (
    <div
      onClick={onClick}
      className="cursor-pointer inline-flex items-center justify-center py-2 text-sm text-gray-700"
    >
      {icon}
      <span className="ml-1 hover:text-gray-500">Claim {balance}</span>
    </div>
  );
}
