import React, { createContext, useEffect, useState } from "react";
import { useWallets } from "@privy-io/react-auth";
import { NETWORK } from "../constants";
import { loadContracts } from "../helpers/contracts";

import { FullClient, APIClient } from "@researchportfolio/client/index";

const ClientContext = createContext(undefined);
ClientContext.displayName = "ClientContext";

const targetNetwork = NETWORK;

function ClientProvider({ children }) {
  const { wallets } = useWallets();
  const [userSigner, setUserSigner] = useState();
  const [ethereumProvider, setEthereumProvider] = useState();

  let wallet;
  if (wallets && wallets.length) {
    for (var i = 0; i < wallets.length; i++) {
      if (wallets[i].connectorType === "embedded") {
        wallet = wallets[i];
        break;
      }
    }

    if (!wallet) {
      wallet = wallets[0];
    }
  }

  useEffect(() => {
    async function getProvider() {
      if (wallet) {
        const newProvider = await wallet.getEthersProvider();
        if (newProvider) {
          const newUserSigner = await newProvider.getSigner();
          setUserSigner(newUserSigner);
          // TODO(dye): Also store the signer itself
          setEthereumProvider(newProvider);
        }
      }
    }
    getProvider();
  }, [wallet]);

  const address = wallet?.address;
  const selectedChainId = wallet?.chainId?.split(":")[1];

  const client =
    userSigner && address
      ? new FullClient({
          loadContracts,
          graphqlURL: "/api/graphql",
          apiURL: "/api",
          fetch: (...a) => fetch(...a),
          signer: userSigner,
          provider: ethereumProvider,
          address: address,
          selectedChainId,
          targetNetwork,
        })
      : new APIClient({
          graphqlURL: "/api/graphql",
          apiURL: "/api",
          fetch: (...a) => fetch(...a),
          targetNetwork,
        });

  return (
    <ClientContext.Provider value={{ client }}>
      {children}
    </ClientContext.Provider>
  );
}

export { ClientContext, ClientProvider };
