import { useState } from "react";
import { ClipboardIcon } from "@heroicons/react/outline"

export default function Copy({text, message}) {
    const [copied, setCopied] = useState(false);
    const icon = copied ? (
        <ClipboardIcon className="w-4 h-4" aria-hidden="true" />
    ) : (
        <ClipboardIcon className="w-4 h-4" style={{color: "#52c41a"}} aria-hidden="true" />
    )

    const copy = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        setCopied(true);
    }

    return (
    <div onClick={copy} className="cursor-pointer inline-flex items-center justify-center py-2 text-sm text-gray-700">            
        {icon}
        <span className="ml-1 hover:text-gray-500">{message}</span>
    </div>   
    )
}