export async function switchNetwork(targetNetwork) {
  const ethereum = window.ethereum;
  const data = [
    {
      chainId: "0x" + targetNetwork.chainId.toString(16),
      chainName: targetNetwork.name,
      nativeCurrency: targetNetwork.nativeCurrency,
      rpcUrls: [targetNetwork.rpcUrl],
      blockExplorerUrls: [targetNetwork.blockExplorer],
    },
  ];

  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: data[0].chainId }],
    });
  } catch (switchError) {
    // not checking specific error code, because maybe we're not using MetaMask
    try {
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: data,
      });
    } catch (addError) {
      // handle "add" error
    }
  }
}

export async function addToWallet(address, symbol, decimals, callback) {
  const ethereum = window.ethereum;
  try {
    const addTx = await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: address,
          symbol: symbol,
          decimals: decimals
        }
      }
    });

    if (addTx && callback) {
      callback(true, "");
    }
  } catch (error) {
    if (callback) {
      callback(false, error)
    }
  }
}
