import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ClientContext } from "../helpers/context";
import { usePrivy } from "@privy-io/react-auth";

import {
  AddToWallet,
  ClaimBalance,
  ClaimFail,
  ClaimSuccess,
  Copy,
  DeployTransaction,
  HeldBalance,
  MessageBanner,
  PageLoading,
} from "../components";

import { NETWORK } from "../constants";

import { optimizeIdentifier } from "../helpers/format";

import { toTitleCase, getMessageStringForMintSuccess } from "../helpers/format";

function urlFromResearchIdentifier(researchIdentifier) {
  const arxivRegex = /^arxiv:(\d{4}\.\d{5})/;
  const arxivExec = arxivRegex.exec(researchIdentifier);
  if (arxivExec) {
    return "https://arxiv.org/abs/" + arxivExec[1];
  }
  return researchIdentifier;
}

const ConfirmStatusButton = ({
  currentStatus,
  verified,
  toggleContractVerifyStatus,
}) => {
  let color;
  let text;
  let toolTipText;
  let buttonClass = "ml-1 font-medium hover:text-gray-500";

  if (!verified) {
    toolTipText = "This paper has not been verified for this contract.";
    text = "Unverified";
    color = "#eb2f96";
  } else {
    toolTipText = "This paper has been verified to match this contract.";
    text = "Verified";
    color = "#52c41a";
    buttonClass = "cursor-default " + buttonClass;
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        toggleContractVerifyStatus();
      }}
      className={buttonClass}
      style={{ color: color }}
    >
      {currentStatus}
    </button>
  );
};

export default function Artifact({ track }) {
  const { artifactId } = useParams();
  const { client } = useContext(ClientContext);
  const { getAccessToken } = usePrivy();

  const [retrievedTokenInfo, setRetrievedTokenInfo] = useState({});
  const [loadedIPFS, setLoadedIPFS] = useState(false);
  const [loadedIPFSError, setLoadedIPFSError] = useState();
  const [loadedVerified, setLoadedVerified] = useState(false);
  const [loadedVerifiedError, setLoadedVerifiedError] = useState();
  const [verified, setVerified] = useState(null);
  const [claimSuccess, setClaimSuccess] = useState(null);
  const [claimError, setClaimError] = useState(null);
  const [heldBalance, setHeldBalance] = useState(null);
  const [claimBalance, setClaimBalance] = useState(null);

  const address = client && client.address;

  const tokenBalancesByType = (balances, ty) => {
    return balances.filter((account) => {
      return account.type == ty;
    });
  };

  useEffect(() => {
    client.getToken(artifactId).then(
      (result) => {
        setRetrievedTokenInfo(result);
        setLoadedIPFS(true);
        setLoadedIPFSError(null);
      },
      (error) => {
        setLoadedIPFS(true);
        setLoadedIPFSError(error);
        setRetrievedTokenInfo({});
      }
    );

    client
      .getVerifiedStatusFromAddress(artifactId)
      .then((result) => {
        if (result.status === "success") {
          setVerified(result.verified);
          setLoadedVerified(true);
          setLoadedVerifiedError(null);
        }
      })
      .catch((error) => {
        setLoadedVerified(true);
        setLoadedVerifiedError(error);
        setVerified(null);
      });
  }, [artifactId]);

  useEffect(() => {
    if (!address) {
      return;
    }

    client.getAllBalancesForAccount(address).then((balances) => {
      const filtered = balances.filter((balance) => {
        return balance.token.address === artifactId;
      });
      const claimTokens = tokenBalancesByType(filtered, "UNCLAIMED").map(
        (balance) => ({
          ...balance.token,
          userClaim: true,
          claimBalance: parseInt(balance.value),
          totalSupplyExact: BigInt(balance.token.totalSupplyExact),
        })
      );

      const heldTokens = tokenBalancesByType(filtered, "HELD").map(
        (balance) => ({
          ...balance.token,
          userClaim: false,
          heldBalance: parseInt(balance.value),
          totalSupplyExact: BigInt(balance.token.totalSupplyExact),
        })
      );

      if (claimTokens && claimTokens.length) {
        setClaimBalance(claimTokens[0].claimBalance);
      } else {
        setClaimBalance(0);
      }
      if (heldTokens && heldTokens.length) {
        setHeldBalance(heldTokens[0].heldBalance);
      } else {
        setHeldBalance(0);
      }
    });
  }, [address, artifactId]);

  if (!loadedIPFS || !loadedVerified) {
    return (
      <div className="min-h-screen pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
        <div className="mx-auto max-w-7xl lg:px-6 xs:px-3">
          <PageLoading />
        </div>
      </div>
    );
  }

  if (!retrievedTokenInfo || Object.keys(retrievedTokenInfo).length === 0) {
    return (
      <div className="min-h-screen pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
        <div className="mx-auto max-w-7xl xs:px-3">
          <p>We do not have this token. Did you enter the address correctly?</p>
        </div>
      </div>
    );
  }

  const claimBalanceCallback = (result) => {
    if (result.success) {
      track("Artifact", "claimUnclaimedTokenSuccess", artifactId);
      setClaimSuccess({
        message:
          "Successfully claimed! We will update here within 15 minutes. Please do not reclaim before then.",
      });
    } else {
      let message = "There was an error claiming";
      if (error.message) {
        message += ": " + error.message;
      } else {
        message += ".";
      }
      track("Artifact", "claimUnclaimedTokenFail", artifactId);
      track("Artifact", "claimUnclaimedTokenError", message);
      setClaimError(message);
    }
  };

  const {
    metadata,
    decimals,
    minter,
    name,
    researchIdentifier,
    symbol,
    transaction,
    totalSupplyExact,
  } = retrievedTokenInfo;
  const confirmationStatus = !loadedVerified
    ? "Unknown"
    : verified
    ? "Verified"
    : "Unverified";
  const tokenAddress = artifactId;
  const etherscanUrl = "https://etherscan.io/address/" + tokenAddress;

  let banner = null;
  const isMinter =
    address && minter && minter.address === address.toLowerCase();
  if (claimError) {
    banner = (
      <ClaimFail
        info={claimError}
        onClose={() => setIsMessageWindowVisible(false)}
      />
    );
  } else if (claimSuccess) {
    banner = (
      <ClaimSuccess
        info={claimSuccess}
        onClose={() => setIsMessageWindowVisible(false)}
      />
    );
  } else if (confirmationStatus === "Unverified") {
    banner = (
      <UnconfirmedBanner
        researchIdentifier={researchIdentifier}
        isMinter={isMinter}
        tokenAddress={tokenAddress}
      />
    );
  } else if (confirmationStatus === "Unknown") {
    banner = <UnknownBanner />;
  }

  const toggleContractVerifyStatus = async () => {
    const minterAddress = minter ? minter.address : null;
    const myAddress = address;
    const authToken = await getAccessToken();
    client
      .toggleContractVerifyStatus(
        authToken,
        myAddress,
        tokenAddress,
        researchIdentifier,
        minterAddress
      )
      .then((resp) => resp.json())
      .then((result) => {
        if (result.status == "success") {
          setVerified(result.verified);
        }
      });
  };

  const etherscanAddressUrl = NETWORK.blockExplorer + "address/" + artifactId;
  return (
    <div className="min-h-screen pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl xs:px-3">
        {banner}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex justify-between">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="tokenName"
              >
                {name}
              </h3>
            </div>
            <div className="mt-1 flex gap-4">
              <p className="py-2 text-sm text-gray-500">
                <a
                  className="px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full"
                  id="tokenSymbol"
                  href={etherscanUrl}
                  target="_blank"
                >
                  {symbol}
                </a>
              </p>
              <Copy text={tokenAddress} message={"Copy Address"} />
              <AddToWallet
                address={tokenAddress}
                symbol={symbol}
                decimals={decimals}
              />
              {transaction ? (
                <DeployTransaction transaction={transaction} />
              ) : (
                ""
              )}
              <div className="inline-flex py-2 text-sm text-gray-500">
                <HeldBalance
                  balance={heldBalance}
                  addressUrl={etherscanAddressUrl}
                  aClass={"inline-flex items-center justify-center"}
                  iconClass={"w-4 h-4"}
                  iconStyle={{ color: "rgb(82, 196, 26)" }}
                />
              </div>
              {claimBalance && claimBalance > 0 ? (
                <ClaimBalance
                  address={tokenAddress}
                  balance={claimBalance}
                  client={client}
                  callback={claimBalanceCallback}
                />
              ) : null}
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Canonical Identifier
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <a
                    id="researchIdentifier"
                    href={urlFromResearchIdentifier(researchIdentifier)}
                    target="_blank"
                  >
                    {researchIdentifier}
                  </a>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Reference Papers
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul
                    role="list"
                    className="border border-gray-200 rounded-md divide-y divide-gray-200"
                  >
                    {getReferences(
                      metadata?.references || [],
                      totalSupplyExact
                    )}
                  </ul>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Confirmation Status?
                </dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                  id="confirmationStatus"
                >
                  <ConfirmStatusButton
                    currentStatus={toTitleCase(confirmationStatus)}
                    toggleContractVerifyStatus={toggleContractVerifyStatus}
                    researchIdentifier={researchIdentifier}
                    verified={verified}
                    researchTokenAddress={tokenAddress}
                  />
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">DOI</dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                  id="doi"
                >
                  {metadata?.doi || "Unknown"}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Authors</dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                  id="authors"
                >
                  {metadata?.authors
                    ? metadata.authors.map((author) => author.name).join(", ")
                    : "Unknown"}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Abstract</dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                  id="abstract"
                >
                  {metadata?.abstract || "Unknown"}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

function UnknownBanner() {
  const color = "yellow";
  const introString =
    "The status of this contract is unknown because we couldn't reach our server properly :(";
  messageString =
    "Please try refreshing the page. If it still persists, contact us.";
  const message = <p className="text-sm">{messageString}</p>;
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

function UnconfirmedBanner({ researchIdentifier, isMinter, tokenAddress }) {
  const introString = "This contract is unconfirmed!";
  const color = isMinter ? "green" : "red";
  let messageString;
  if (isMinter) {
    messageString = getMessageStringForMintSuccess(
      researchIdentifier,
      tokenAddress
    );
  } else {
    messageString =
      "The author of this paper has not yet confirmed this token.";
  }

  const message = <p className="text-sm">{messageString}</p>;
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

// Unused, need to figure this one out... It's for when a different token of the
// same paper has been verified.
function DisconfirmedBanner({ validTokenAddress }) {
  const introString = "This contract is invalid!";
  const color = "red";
  const message = (
    <p className="text-sm">
      The author of this paper has confirmed a different{" "}
      <Link
        className="text-gray-900 text-sm font-medium truncate underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
        to={`/research/${validTokenAddress}`}
      >
        token
      </Link>{" "}
      as valid.
    </p>
  );
  return (
    <MessageBanner color={color} introString={introString} message={message} />
  );
}

function getReferences(references, totalSupplyExact) {
  const multiplier = BigInt(10 ** 8);
  const totalReferenceEarnings = references
    .map((reference) => BigInt(reference.amount))
    .reduce((prev, curr) => prev + curr, BigInt(0));
  return references.map((reference, index) => {
    const numerator = BigInt(reference.amount) * multiplier;
    const percentReference =
      Number(numerator / totalReferenceEarnings) / 10 ** 6;
    const percentTotal = Number(numerator / BigInt(totalSupplyExact)) / 10 ** 6;

    return (
      <li
        key={`reference${index}`}
        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
      >
        <span id={`referenceName${index}`} className="ml-2 flex-1 w-0 truncate">
          <a href={reference.researchIdentifier} target="_blank">
            {optimizeIdentifier(reference.researchIdentifier)}
          </a>
        </span>
        <div className="ml-4 flex-shrink-0">
          <span id={`referencePercent${index}`}>
            {percentReference}% of References / {percentTotal}% of Total
          </span>
        </div>
      </li>
    );
  });
}
