import { utils } from 'ethers';
import { useState, useEffect } from 'react';
const lookupAddress = async (provider, address) => {
  if (utils.isAddress(address)) {
    try {
      // Accuracy of reverse resolution is not enforced.
      // We then manually ensure that the reported ens name resolves to address
      const reportedName = await provider.lookupAddress(address);
      const resolvedAddress = await provider.resolveName(reportedName);
      if (address && utils.getAddress(address) === utils.getAddress(resolvedAddress)) {
        return reportedName;
      }
      else {
        return utils.getAddress(address);
      }
    }
    catch (e) {
      return utils.getAddress(address);
    }
  }
  return '';
};
/**
 * Gets ENS name from given address and provider
 * @param provider (TEthersProvider)
 * @param address (string)
 * @returns (string) ens name
 */
export const useLookupAddress = (provider, address) => {
  const [ensName, setEnsName] = useState(address);
  useEffect(() => {
    const storedData = window.localStorage.getItem('ensCache_' + address);
    const cache = JSON.parse(storedData !== null && storedData !== void 0 ? storedData : '{}');
    if (cache && (cache === null || cache === void 0 ? void 0 : cache.name) && (cache === null || cache === void 0 ? void 0 : cache.timestamp) > Date.now()) {
      setEnsName(cache === null || cache === void 0 ? void 0 : cache.name);
    }
    else if (provider) {
      void lookupAddress(provider, address).then((name) => {
        if (name) {
          setEnsName(name);
          window.localStorage.setItem('ensCache_' + address, JSON.stringify({
            timestamp: Date.now() + 360000,
            name,
          }));
        }
      });
    }
  }, [address, provider, setEnsName]);
  return ensName;
};
