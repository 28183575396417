import {
  gql,
  createClient,
  Client as URQLClient,
  ClientOptions as URQLClientOptions,
} from "@urql/core";
import { Web3Provider } from "@ethersproject/providers";
import { URLSearchParams } from "url";
import { ethers } from "ethers";

import { BigNumber, Contract, ContractTransaction, Signer } from "ethers";
import { pollWhile } from "./async";

export const zeroAddressBytes =
  "0x0000000000000000000000000000000000000000000000000000000000000000";
export const zeroAddress = "0x0000000000000000000000000000000000000000";

/*
This function is used to get the event args from a transaction receipt.
Args:
  tx: The transaction to get the event args from.
  logKey: The name of the event to get the args from.
  doGas: Whether or not to print the amount of gas used by the transaction.
Returns:
  The event args from the transaction receipt.
*/
export const eventArgsFromLog = async (
  tx: ContractTransaction,
  logKey: string,
  doGas?: boolean
) => {
  console.log("Waiting for tx to be mined...");
  const receipt = await tx.wait();
  console.log("Tx mined!");
  const gas = receipt.gasUsed;
  if (doGas) {
    // Rinkeby: Amount of gas for the contract: 535995
    console.log("Amount of gas for the contract: " + gas.toString());
  }
  const filteredLogs = receipt.events
    ? receipt.events.filter((e) => e.event && e.event === logKey)
    : undefined;
  if (filteredLogs && filteredLogs.length == 1) {
    const eventLog = filteredLogs[0];
    if (eventLog.args) {
      return eventLog.args;
    }
  }

  throw new Error(`Missing event ${logKey}`);
};

export const getDistributionsFromTokenDistributionsWithFunc = async (
  tokenDistributions,
  retrievalFunc
) => {
  let tokenDistributionByAddress = {};
  tokenDistributions.forEach(({ account, amount, percent }) => {
    tokenDistributionByAddress[account.address] = { percent, amount };
  });

  const tokenHolders = await Promise.all(
    tokenDistributions.map((tokenDistribution) =>
      retrievalFunc(tokenDistribution.account.address)
    )
  );

  // Now we need to make a dict of the holders and their balances, weighted by the distribution amt.
  let holderDict: { [key: string]: BigNumber } = {};
  tokenHolders.forEach((token, index) => {
    // We need the total amount for this token. --> Sum valueExact to get totalExact
    // Then we need to divide each holder's valueExact by totalExact to get the percent.
    // Then we need to multiple that by the percent in the tokenDistributionByAddress.
    // That's how many tokens that this address should end up with.

    // Get the totalExact by reducing the valueExact of each holder.
    const totalExact = token.reduce(
      (total, holder) => total.add(BigNumber.from(holder.valueExact)),
      BigNumber.from(0)
    );

    token.forEach((holder) => {
      const thisTokenAmount = tokenDistributionByAddress[
        holder.tokenAddress
      ].amount
        .mul(BigNumber.from(holder.valueExact))
        .div(totalExact);
      if (holder.account in holderDict) {
        holderDict[holder.account] =
          holderDict[holder.account].add(thisTokenAmount);
      } else {
        holderDict[holder.account] = thisTokenAmount;
      }
    });
  });

  // Now we need to convert the dict to a list of objects.
  let holderList: { account: { address: string }; amount: BigNumber }[] = [];
  for (const [key, value] of Object.entries(holderDict)) {
    holderList.push({ account: { address: key }, amount: value });
  }
  return holderList;
};

export const getReferenceDistributions = (
  urls,
  addresses,
  minters,
  percents,
  fraction,
  supply,
  decimals
) => {
  let multisigDirect = BigNumber.from(0);
  let researchContractDistributions: {
    account: { address: string };
    amount: BigNumber;
    percent: number;
  }[] = [];
  let references: {
    amount: BigNumber;
    url: string;
    address?: string;
    minter?: string;
  }[] = [];

  for (var index = 0; index < urls.length; index++) {
    const url = urls[index];
    const referenceAddress = addresses[index];
    const referenceMinter = minters[index];
    const percent = percents[index] / 100;

    // We split up the percent multiplications because otherwise we hit an underflow.
    let amount = supply
      .mul(BigNumber.from(percent * 10 ** 8))
      .div(BigNumber.from(10 ** 8))
      .mul(BigNumber.from(fraction * 10 ** 8))
      .div(BigNumber.from(10 ** 8))
      .mul(decimals);
    if (referenceAddress && referenceAddress != zeroAddress) {
      references.push({
        minter: referenceMinter,
        address: referenceAddress,
        amount,
        url,
      });
      researchContractDistributions.push({
        account: { address: referenceAddress },
        amount,
        percent,
      });
    } else {
      references.push({ amount, url });
      // The amt for this reference is going straight to the multisig to hold.
      multisigDirect = multisigDirect.add(amount);
    }
  }

  return { multisigDirect, researchContractDistributions, references };
};

export const getAuthorDistributions = (
  names,
  emails,
  addresses,
  roles,
  decimals,
  share
) => {
  if (!names || !emails || !addresses || !roles) {
    return [];
  }

  let firstAuthors: {
    name: string;
    email: string;
    role: string;
    address: string;
  }[] = [];
  let middleAuthors: {
    name: string;
    email: string;
    role: string;
    address: string;
  }[] = [];
  let piAuthors: {
    name: string;
    email: string;
    role: string;
    address: string;
  }[] = [];
  for (var index = 0; index < names.length; index++) {
    const name = names[index];
    const email = emails[index];
    const address = addresses[index];
    const role = roles[index];
    if (role.value == "first") {
      firstAuthors.push({ name, email, address, role: "first" });
    } else if (role.value == "middle") {
      middleAuthors.push({ name, email, address, role: "middle" });
    } else if (role.value == "pi") {
      piAuthors.push({ name, email, address, role: "pi" });
    }
  }

  // If we dont have pis or middles, redistribute.
  let mulConstant = BigNumber.from(10 ** 8);
  let firstAuthorShare = BigNumber.from(7);
  let middleAuthorShare =
    middleAuthors.length > 0 ? BigNumber.from(1) : BigNumber.from(0);
  middleAuthorShare = middleAuthorShare;
  let piAuthorShare =
    piAuthors.length > 0 ? BigNumber.from(2) : BigNumber.from(0);
  piAuthorShare = piAuthorShare;
  const authorShareSum = firstAuthorShare
    .add(middleAuthorShare)
    .add(piAuthorShare);

  firstAuthorShare = firstAuthorShare.mul(mulConstant).div(authorShareSum);
  middleAuthorShare = middleAuthorShare.mul(mulConstant).div(authorShareSum);
  piAuthorShare = piAuthorShare.mul(mulConstant).div(authorShareSum);
  firstAuthorShare = firstAuthorShare.div(BigNumber.from(firstAuthors.length));

  if (middleAuthors.length > 0) {
    middleAuthorShare = middleAuthorShare.div(
      BigNumber.from(middleAuthors.length)
    );
  }

  if (piAuthors.length > 0) {
    piAuthorShare = piAuthorShare.div(BigNumber.from(piAuthors.length));
  }

  firstAuthors = firstAuthors.map((author) => ({
    ...author,
    amount: decimals.mul(share.mul(firstAuthorShare).div(mulConstant)),
  }));

  middleAuthors = middleAuthors.map((author) => ({
    ...author,
    amount: decimals.mul(share.mul(middleAuthorShare).div(mulConstant)),
  }));

  piAuthors = piAuthors.map((author) => ({
    ...author,
    amount: decimals.mul(share.mul(piAuthorShare).div(mulConstant)),
  }));

  return { firstAuthors, middleAuthors, piAuthors };
};

const UNCLAIMED_QUERY = gql`
  query unclaimed(
    $accountAddress: Address!
    $tokenAddress: Address!
    $block: BlockNumber
  ) {
    unclaimed(
      account: $accountAddress
      unclaimedToken: $tokenAddress
      block: $block
    ) {
      ... on DistributorV2ClaimForAccount {
        distributor
        index
        amount
        proof
      }
    }
  }
`;

export const publishAirdropDistributorV2 = gql`
  mutation publishAirdropDistributorV2(
    $input: PublishAirdropDistributorV2Input!
  ) {
    publishAirdropDistributorV2(input: $input) {
      cid
      json
    }
  }
`;

export const API_STATUS_QUERY = gql`
  query {
    _meta {
      block {
        number
      }
    }
  }
`;

const PUBLISH_INTEREST_TO_AIRTABLE = gql`
  mutation ($input: AirtableInterestInput!) {
    publishInterestToAirtable(input: $input)
  }
`;

const GET_PAPER_METADATA_QUERY = gql`
  query ($input: String!) {
    getPaperMetadata(identifier: $input) {
      arxivId
      doi
      title
      authors {
        name
        email
        uri
      }
      abstract
      publishedAt
    }
  }
`;

/*
  Optimize an identifier before pushing it on chain. Only set up for arxiv atm.
  Args:
    researchIdentifier: The identifier to optimize.
  Returns:
    The string optimized identifier.
*/
function _optimizeIdentifier(researchIdentifier) {
  const arxivRegex = /^.*arxiv.org\/(abs|pdf)\/(\d{4}\.\d{5}).*/;

  const arxivCheck = arxivRegex.exec(researchIdentifier);
  if (arxivCheck) {
    return "arxiv:" + arxivCheck[2];
  } else {
    return researchIdentifier;
  }
}

export interface ClientNetwork {
  name: string;
  chainId: number;
  color?: string;
  blockExplorer?: string;
  rpcUrl?: string;
}

export class APIClient {
  private readonly apiURL: string;
  private graphqlClient: URQLClient;
  public readonly targetNetwork: ClientNetwork;

  constructor({
    apiURL,
    graphqlURL,
    fetch,
    targetNetwork,
  }: {
    apiURL: string;
    graphqlURL: string;
    fetch?: URQLClientOptions["fetch"];
    targetNetwork: ClientNetwork;
  }) {
    this.apiURL = apiURL;
    this.graphqlClient = createClient({
      fetch,
      url: graphqlURL,
      requestPolicy: "network-only",
    });

    this.targetNetwork = targetNetwork;
  }

  // client: Client, q, v) {
  private async query(q, v) {
    const { data, error } = await this.graphqlClient.query(q, v).toPromise();
    if (error) {
      throw error;
    }
    return data;
  }

  // client: Client, q, v) {
  private async mutate(q, v) {
    const { data, error } = await this.graphqlClient.mutation(q, v).toPromise();
    if (error) {
      throw error;
    }

    return data;
  }

  async createTokenWithManifest(distributor, jsonResult, token) {
    const url =
      process.env.API_PROTOCOL +
      "://" +
      process.env.API_HOST +
      "/api/createTokenWithManifest";
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        distributorAddress: distributor,
        manifest: jsonResult,
        tokenAddress: token,
      }),
    });
  }

  /*
  Calls to the server to get a contract address from a research identifier.
  Args:
    researchIdentifier: The string identifier to get the address for.
  Returns:
    An object with the address of the contract or none if it doesn't exist.
  */
  async getAddressFromResearchIdentifier(researchIdentifier) {
    return fetch(
      `/api/getAddressFromResearchIdentifier?` +
        new URLSearchParams({
          researchIdentifier: _optimizeIdentifier(researchIdentifier),
        })
    ).then((r) => r.json());
  }

  /*
  Calls to the server to get a privy embedded wallet user from an email.
  Args:
    email: The string email.
  Returns:
    An object with fields address and email if exists.
  */
  async getOrCreatePrivyUser(email) {
    const url =
      this.apiURL +
      "/getOrCreatePrivyUser?" +
      new URLSearchParams({ email: email });
    return fetch(url);
  }

  async getVerifiedStatusFromAddresses(addresses) {
    const searchParams = new URLSearchParams({
      addresses: addresses.join(","),
    });
    return fetch(`/api/getVerifiedStatusFromAddresses?` + searchParams).then(
      (r) => r.json()
    );
  }

  async getVerifiedStatusFromAddress(address) {
    const searchParams = new URLSearchParams({
      address: address,
    });
    return fetch(`/api/getVerifiedStatusFromAddress?` + searchParams).then(
      (r) => r.json()
    );
  }

  async toggleContractVerifyStatus(
    authToken,
    myAddress,
    contractAddress,
    researchIdentifier,
    minter
  ) {
    return fetch(`/api/toggleContractVerifyStatus`, {
      method: "POST",
      body: JSON.stringify({
        myAddress: myAddress,
        contractAddress: contractAddress,
        researchIdentifier: researchIdentifier,
        minter: minter,
      }),
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }

  async _prepareDataForMinting(
    blockNumber: BigNumber,
    distributions: (
      | {
          amount: BigNumber;
          erc1155Token?: undefined;
          account: { address: string };
        }
      | {
          amount: BigNumber;
          erc1155Token: { address: string; tokenID: BigNumber };
          account?: undefined;
        }
    )[]
  ) {
    let distributorBalance = BigNumber.from(0);
    for (const { amount } of distributions) {
      distributorBalance = distributorBalance.add(amount);
    }

    let merkleRoot = zeroAddressBytes;
    let distributorManifest = "0x0";
    let jsonResult = {};

    if (distributions.length) {
      const input = {
        basedOnBlockNumber: blockNumber.toString(),
        entries: distributions.flatMap(({ amount, account }) => [
          ...(account
            ? [{ account: { ...account, amount: amount.toString() } }]
            : []),
        ]),
      };
      const {
        publishAirdropDistributorV2: { json, cid },
      } = await this.mutate(publishAirdropDistributorV2, { input });
      jsonResult = json;
      distributorManifest = cid;
      merkleRoot = JSON.parse(json).merkleRoot;
    }

    return { distributorBalance, distributorManifest, jsonResult, merkleRoot };
  }

  async publishAirtableInterest(fields) {
    const { publishInterestToAirtable } = await this.mutate(
      PUBLISH_INTEREST_TO_AIRTABLE,
      {
        input: fields,
      }
    );
    return publishInterestToAirtable;
  }

  // Get the holders for each token in the token distribution and merge them.
  // Args:
  //  - tokenDistributions: [account: { address: string }, amount: BigNumber, erc1155Token?: { address: string, tokenID: BigNumber }]
  // Returns:
  //  - holderList: A {account: {address: string}, amount: BigNumber}[]
  async getDistributionsFromTokenDistributions(tokenDistributions) {
    // TODO: The tests wont pass like this...
    let tokenDistributionByAddress = {};
    tokenDistributions.forEach(({ account, amount, percent }) => {
      tokenDistributionByAddress[account.address] = { percent, amount };
    });

    const tokenHolders = await Promise.all(
      tokenDistributions.map((tokenDistribution) =>
        this.getAllBalancesForToken(tokenDistribution.account.address)
      )
    );

    // Now we need to make a dict of the holders and their balances, weighted by the distribution amt.
    let holderDict: { [key: string]: BigNumber } = {};
    tokenHolders.forEach((token, index) => {
      // We need the total amount for this token. --> Sum valueExact to get totalExact
      // Then we need to divide each holder's valueExact by totalExact to get the percent.
      // Then we need to multiple that by the percent in the tokenDistributionByAddress.
      // That's how many tokens that this address should end up with.

      // Get the totalExact by reducing the valueExact of each holder.
      const totalExact = token.reduce(
        (total, holder) => total.add(BigNumber.from(holder.valueExact)),
        BigNumber.from(0)
      );

      token.forEach((holder) => {
        const thisTokenAmount = tokenDistributionByAddress[
          holder.tokenAddress
        ].amount
          .mul(BigNumber.from(holder.valueExact))
          .div(totalExact);
        if (holder.account in holderDict) {
          holderDict[holder.account] =
            holderDict[holder.account].add(thisTokenAmount);
        } else {
          holderDict[holder.account] = thisTokenAmount;
        }
      });
    });

    // Now we need to convert the dict to a list of objects.
    let holderList: { account: { address: string }; amount: BigNumber }[] = [];
    for (const [key, value] of Object.entries(holderDict)) {
      holderList.push({ account: { address: key }, amount: value });
    }
    return holderList;
  }

  async publishPaperInfoToIPFS(paperInfoInput) {
    const PUBLISH_PAPER_INFO_TO_IPFS_QUERY = gql`
      mutation ($input: PaperInfoInput!) {
        publishPaperInfoToIPFS(input: $input) {
          cid
          data {
            abstract
            title
            authors {
              name
            }
            doi
            references {
              address
              amount
              researchIdentifier
            }
          }
        }
      }
    `;

    const {
      publishPaperInfoToIPFS: { cid, data },
    } = await this.mutate(PUBLISH_PAPER_INFO_TO_IPFS_QUERY, {
      input: paperInfoInput,
    });

    return { cid, data };
  }

  async publishMerkleRoot(addresses: string[], amount: string[]) {
    // addresses is a list of ethereum string addresses; amount is a list of big ints.
    if (addresses.length !== amount.length) {
      return false;
    }
    if (addresses.length == 0) {
      return false;
    }

    const MERKLE_ROOT_MUTATION = gql`
      mutation publishMerkle($input: PublishAirdropDistributorV2Input!) {
        publishAirdropDistributorV2(input: $input) {
          json
        }
      }
    `;

    const r = await this.query(API_STATUS_QUERY, {});

    const latestBlockNumber = BigNumber.from(r._meta.block.number).toString();
    const entries = [...Array(addresses.length).keys()].map((index) => {
      return { address: addresses[index], amount: amount[index] };
    });

    const publishAirdropDistributorV2Input = {
      basedOnBlockNumber: latestBlockNumber,
      entries: entries,
    };
    return this.mutate(MERKLE_ROOT_MUTATION, {
      input: publishAirdropDistributorV2Input,
    });
  }

  // Called in views/Artifact.jsx
  async getToken(tokenAddress: string) {
    const TOKEN_QUERY = gql`
      query token($address: Address!) {
        token(address: $address) {
          metadata {
            abstract
            authors {
              name
            }
            doi
            references {
              address
              amount
              researchIdentifier
            }
          }
          blockNumber
          cid
          decimals
          minter {
            address
          }
          name
          researchIdentifier
          symbol
          totalSupplyExact
          transaction {
            hash
            timestamp
          }
        }
      }
    `;

    const { token } = await this.query(TOKEN_QUERY, { address: tokenAddress });

    return token;
  }

  async getDatabaseDistributionsForAccount(accountAddress: string) {
    if (!accountAddress) {
      return [];
    }

    const searchParams = new URLSearchParams({
      address: accountAddress,
    });

    // TODO: Fix this so that it's not just localhost. Cinjon got annoyed.
    // const url =
    //   "http://localhost:8080/api/getDatabaseDistributionsForAccount?" +
    //   searchParams;
    const url =
      "https://www.researchportfolio.co/api/getDatabaseDistributionsForAccount?" +
      searchParams;
    return fetch(url).then((r) => r.json());
  }

  async getAllBalancesForToken(
    tokenAddress: string,
    filter?: (accountAddress: string) => boolean
  ) {
    // This is using the big query, not the reduced one with the where clause.
    const TOKEN_BALANCES_QUERY = gql`
      query tokenBalances($address: Address!) {
        token(address: $address) {
          blockNumber
          decimals
          totalSupplyExact
          balances {
            account {
              address
            }
            value
            valueExact
            type
          }
        }
      }
    `;

    const { token } = await this.query(TOKEN_BALANCES_QUERY, {
      address: tokenAddress,
    });
    let balances: {
      account: string;
      valueExact: string;
      value: string;
      type: string;
    }[] = token.balances
      .map(({ account, type, valueExact, value }) => ({
        account: account.address,
        type,
        valueExact,
        value,
        tokenAddress,
      }))
      .filter(({ account }) => (filter ? filter(account) : true));

    return balances;
  }

  // Used by the call in views/Research.jsx to get the balances of a user.
  async getAllBalancesForAccount(
    accountAddress: string,
    filter?: (tokenAddress: string) => boolean
  ) {
    const ACCOUNT_HOLDINGS_QUERY = gql`
      query accountHoldings($address: Address!) {
        account(address: $address) {
          blockNumber
          balances {
            token {
              address
              cid
              decimals
              metadata {
                doi
                references {
                  address
                  amount
                  researchIdentifier
                }
              }
              minter {
                address
              }
              name
              researchIdentifier
              symbol
              totalSupplyExact
              transaction {
                hash
                timestamp
              }
            }
            valueExact
            value
            type
          }
        }
      }
    `;

    const { account } = await this.query(ACCOUNT_HOLDINGS_QUERY, {
      address: accountAddress,
    });
    let balances = account.balances;
    if (filter) {
      balances = balances.filter(({ token: { address } }) => filter(address));
    }
    return balances;
  }

  // Used by views/Research.jsx to get all research tokens for displaying.
  async getAllResearchTokens() {
    const ALL_RESEARCH_TOKENS_QUERY = gql`
      query {
        tokens {
          blockNumber
          address
          cid
          decimals
          minter {
            address
          }
          name
          researchIdentifier
          metadata {
            doi
            references {
              address
              amount
              researchIdentifier
            }
          }
          symbol
          totalSupplyExact
        }
      }
    `;
    return this.query(ALL_RESEARCH_TOKENS_QUERY, {});
  }

  async getPaperMetadata(arxivInputString: string) {
    const {
      getPaperMetadata: { arxivId, doi, title, authors, abstract, publishedAt },
    } = await this.query(GET_PAPER_METADATA_QUERY, { input: arxivInputString });
    return {
      arxivId,
      doi,
      title,
      authors: authors.map(({ name, email, uri }) => ({ name, email, uri })),
      abstract,
      publishedAt,
    };
  }

  private async doesAPIIncludeBlock(block) {
    try {
      const r = await this.query(API_STATUS_QUERY, {});

      const latestBlockNumber = BigNumber.from(r._meta.block.number);
      return latestBlockNumber.gte(block);
    } catch (e: any) {
      if (e.graphQLErrors) {
        // Subgraphs don't actually start syncing until they see a matching event. In some cases
        // this may not happen until the test has started executing. Allow some time for the subgraph
        // to catch up if we see this error.
        const subgraphNotReaderError = e.graphQLErrors.find(
          ({ message }: { message: string }) =>
            message.match(/has not started syncing yet/i)
        );
        if (subgraphNotReaderError) {
          return false;
        }
      }
      throw e;
    }
  }

  // ...txns: ContractTransaction[]): Promise<ContractReceipt[]> {
  async untilAPIIncludesTransaction(...txns) {
    const done = await Promise.all(txns.map((txn) => txn.wait()));
    const blockNumber = Math.max(...done.map((txn) => txn.blockNumber));
    await pollWhile(() => this.doesAPIIncludeBlock(blockNumber));
    return done;
  }

  async accountHoldersQuery(address) {
    const ACCOUNT_HOLDERS_QUERY = gql`
      query accountHoldings($address: Address!) {
        account(address: $address) {
          blockNumber
          held {
            token {
              decimals
              name
              symbol
              address
            }
            valueExact
            value
          }
        }
      }
    `;
    return this.query(ACCOUNT_HOLDERS_QUERY, {
      address: address.toLowerCase(),
    });
  }

  async tokenHoldersQuery(address) {
    const TOKEN_HOLDERS_QUERY = gql`
      query tokenHolders($address: Address!) {
        token(address: $address) {
          blockNumber
          held {
            account {
              address
            }
            value
            valueExact
          }
        }
      }
    `;
    return this.query(TOKEN_HOLDERS_QUERY, { address: address.toLowerCase() });
  }

  async contractHoldersQuery(contractAddress, block) {
    const CONTRACT_HOLDERS_QUERY = gql`
      query contractHolders($contractAddress: ID!, $block: Block_height) {
        erc20Contract(id: $contractAddress, block: $block) {
          totalSupply {
            value
            valueExact
          }
          balances(
            orderBy: valueExact
            orderDirection: desc
            where: { account_not: null }
          ) {
            account {
              id
            }
            value
            valueExact
          }
        }
      }
    `;
    return this.query(CONTRACT_HOLDERS_QUERY, {
      contractAddress: contractAddress.toLowerCase(),
      block: block,
    });
  }

  async unclaimedForTokenQuery({
    tokenAddress,
    accountAddress,
  }: {
    tokenAddress: string;
    accountAddress: string;
  }) {
    const { unclaimed } = await this.query(UNCLAIMED_QUERY, {
      tokenAddress,
      accountAddress,
    });
    return unclaimed;
  }
}

export class FullClient {
  private apiClient: APIClient;
  private loadContracts: () => Promise<{ Entrypoint: Contract }>;
  private signer: Signer;
  private provider: Web3Provider;
  public readonly address: string;
  public readonly targetNetwork: ClientNetwork;
  public readonly selectedChainId: string;

  constructor({
    loadContracts,
    apiURL,
    graphqlURL,
    fetch,
    targetNetwork,
    selectedChainId,
    signer,
    provider,
    address,
  }: {
    loadContracts: () => Promise<{ Entrypoint: Contract }>;
    apiURL: string;
    graphqlURL: string;
    address: string;
    fetch?: URQLClientOptions["fetch"];
    selectedChainId: string;
    targetNetwork: ClientNetwork;
    signer: Signer;
    provider: Web3Provider;
  }) {
    this.loadContracts = loadContracts;

    this.targetNetwork = targetNetwork;
    this.address = address;
    this.selectedChainId = selectedChainId;
    this.signer = signer;
    this.provider = provider;
    this.apiClient = new APIClient({
      fetch,
      apiURL,
      graphqlURL,
      targetNetwork,
    });
  }

  getOrCreatePrivyUser(email) {
    return this.apiClient.getOrCreatePrivyUser(email);
  }

  getAddressFromResearchIdentifier(researchIdentifier) {
    return this.apiClient.getAddressFromResearchIdentifier(researchIdentifier);
  }

  claimAmaranthPrize(authToken, targetAddress, accountAddress) {
    console.log("Selected chain id: ", this.selectedChainId);
    var tokenAddress = process.env.TOKEN_ADDRESS?.toLowerCase();
    if (!tokenAddress) {
      console.error("No token address set.");
      tokenAddress = "";
    }
    console.log("Account address: ", accountAddress);
    this.getDatabaseDistributionsForAccount(accountAddress).then(
      (distributionsJson) => {
        console.log("Distributions: ", distributionsJson);
        var claims = distributionsJson.tokens
          .filter((token) => token.claimed === false)
          .filter((token) => token.tokenAddress.toLowerCase() === tokenAddress);
        console.log("Unclaimed: ", claims);
        console.log("Token address: ", tokenAddress);
        console.log(claims);
        if (claims.length === 0) {
          return Promise.reject(
            "No valid claims found for this account. Has a claim already been made?"
          );
        }

        console.log(
          "claim Token address: ",
          claims[0].tokenAddress.toLowerCase()
        );
        if (claims.length !== 1) {
          throw new Error(
            "No valid claims found for this account. Has a claim already been made?"
          );
        }
        const claim = claims[0];
        // Construct json request to send to server.
        var claimRequest = {};
        claimRequest["accountAddress"] = accountAddress;
        claimRequest["targetAddress"] = targetAddress;
        claimRequest["distributor"] = claim.distributorAddress;
        claimRequest["index"] = claim.index;
        claimRequest["amount"] = claim.amountHex;
        claimRequest["proof"] = claims[0].proof
          .split(",")
          .map((proof) => proof.trim());
        console.log("Sending the claim request: ", claimRequest);

        // Call backend.
        return fetch(`/api/claimAmaranthPrize`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(claimRequest),
        });
      }
    );
  }

  async getBalance(address: string) {
    var tokenAddress = process.env.TOKEN_ADDRESS;
    console.log("Token address: ", tokenAddress);
    if (!tokenAddress) {
      console.error("No token address set.");
      tokenAddress = "";
    }

    const usdcContract = new ethers.Contract(
      tokenAddress,
      ["function balanceOf(address) view returns (uint256)"],
      this.signer.provider
    );
    const balance = await usdcContract.balanceOf(address);
    return usdcContract.balanceOf(address);
  }

  async transferFunds(
    targetAddress: string,
    amount: BigNumber
  ): Promise<ethers.providers.TransactionReceipt> {
    var tokenAddress = process.env.TOKEN_ADDRESS;
    if (!tokenAddress) {
      console.error("No token address set.");
      tokenAddress = "";
    }

    // Connect to the USDC contract
    const usdcContract = new ethers.Contract(
      tokenAddress,
      ["function transfer(address to, uint256 amount)"],
      this.provider
    );

    console.log(
      "Connect to usdc contract with address ",
      tokenAddress,
      " sending to ",
      targetAddress,
      " amount ",
      amount.toString()
    );

    try {
      console.log(" from address ", this.signer.getAddress());
    } catch (e) {
      console.log("Error getting signer address: ", e);
    }

    try {
      console.log("11 Attempting to transfer: ", amount.toString());
      // Estimate gas limit
      const gasLimitEstimate = await usdcContract
        .connect(this.signer)
        .estimateGas.transfer(targetAddress, amount);

      console.log("Gas Limit estimate: ", gasLimitEstimate);

      // Get current gas price
      const gasPrice = await this.signer.getGasPrice();

      console.log("Gas Price: ", gasPrice);

      // Transfer USDC to the recipient with estimated gas parameters
      const tx = await usdcContract
        .connect(this.signer)
        .transfer(targetAddress, amount, {
          gasLimit: gasLimitEstimate,
          gasPrice: gasPrice,
        });
      console.log("11 Wait on transaction: ", tx);
      return tx.wait();
    } catch (e) {
      console.log("Error transferring: ", e);

      // Transfer USDC to the recipient
      const tx = await usdcContract
        .connect(this.signer)
        .transfer(targetAddress, amount);
      console.log("22 Wait on transaction: ", tx);
      return tx.wait();
    }
  }

  async getTransactionStatus(hash): Promise<boolean> {
    const receipt = await this.provider.getTransactionReceipt(hash);
    if (receipt) {
      if (receipt.status === 1) {
        console.log("Transaction succeeded!");
        console.log("Transaction receipt:", receipt);
        return true;
      } else {
        console.log("Transaction failed!");
        return false;
      }
    } else {
      console.log("Transaction not mined yet. Please wait and check again.");
      return false;
    }
  }

  getVerifiedStatusFromAddresses(addresses) {
    return this.apiClient.getVerifiedStatusFromAddresses(addresses);
  }

  getVerifiedStatusFromAddress(address) {
    return this.apiClient.getVerifiedStatusFromAddress(address);
  }

  prepareDataForMinting(blockNumber, distributions) {
    return this.apiClient._prepareDataForMinting(blockNumber, distributions);
  }

  createTokenWithManifest(distributor, jsonResult, token) {
    return this.apiClient.createTokenWithManifest(
      distributor,
      jsonResult,
      token
    );
  }

  toggleContractVerifyStatus(
    authToken,
    myAddress,
    contractAddress,
    researchIdentifier,
    minterAddress
  ) {
    return this.apiClient.toggleContractVerifyStatus(
      authToken,
      myAddress,
      contractAddress,
      researchIdentifier,
      minterAddress
    );
  }

  publishAirtableInterest(fields) {
    return this.apiClient.publishAirtableInterest(fields);
  }

  publishPaperInfoToIPFS(paperInfoInput) {
    return this.apiClient.publishPaperInfoToIPFS(paperInfoInput);
  }

  publishMerkleRoot(addresses: string[], amount: string[]) {
    return this.apiClient.publishMerkleRoot(addresses, amount);
  }

  getToken(tokenAddress: string) {
    return this.apiClient.getToken(tokenAddress);
  }

  getAllBalancesForToken(
    tokenAddress: string,
    filter?: (accountAddress: string) => boolean
  ) {
    return this.apiClient.getAllBalancesForToken(tokenAddress, filter);
  }

  // Called in views/Research.jsx to get user's distributions held in the RDBS.
  getDatabaseDistributionsForAccount(accountAddress: string) {
    return this.apiClient.getDatabaseDistributionsForAccount(accountAddress);
  }

  // Called in views/Research.jsx to get user's balances.
  getAllBalancesForAccount(
    accountAddress: string,
    filter?: (tokenAddress: string) => boolean
  ) {
    return this.apiClient.getAllBalancesForAccount(accountAddress, filter);
  }

  getAllResearchTokens() {
    return this.apiClient.getAllResearchTokens();
  }

  getPaperMetadata(arxivInputString: string) {
    return this.apiClient.getPaperMetadata(arxivInputString);
  }

  untilAPIIncludesTransaction(...txns) {
    return this.apiClient.untilAPIIncludesTransaction(...txns);
  }

  accountHoldersQuery(address: string) {
    return this.apiClient.accountHoldersQuery(address);
  }

  tokenHoldersQuery(address: string) {
    return this.apiClient.tokenHoldersQuery(address);
  }

  contractHoldersQuery(contractAddress: string, block: string) {
    return this.apiClient.contractHoldersQuery(contractAddress, block);
  }

  getDistributionsFromTokenDistributions(tokenDistributions) {
    return this.apiClient.getDistributionsFromTokenDistributions(
      tokenDistributions
    );
  }

  /* Combine author eth addresses that we know with Privy addresses for emails.
  Args:
    authors: {address: string | null, email: string | null ...}[]
  Returns:
    authors: {address: string, email: string | null, ...}[]
  */
  async getAuthorsWithAddresses(authors) {
    const authorsWithKnownAddresses = authors.filter(
      (author) => author.address != ""
    );
    let authorsWithUnknownAddresses = authors.filter(
      (author) => author.address === ""
    );

    const privyAddressesForUnknownAuthors = await Promise.all(
      authorsWithUnknownAddresses.map((author) =>
        this.getOrCreatePrivyUser(author.email)
          .then((response) => {
            return response.json();
          })
          .then((user) => {
            return {
              address: user.address,
              amount: author.amount,
              email: author.email,
            };
          })
          .catch((e) => {
            console.log("error: ", e);
            throw e;
          })
      )
    );

    authorsWithUnknownAddresses = authorsWithUnknownAddresses.map(
      (author, index) => {
        return {
          ...author,
          address: privyAddressesForUnknownAuthors[index].address,
        };
      }
    );

    return authorsWithKnownAddresses.concat(authorsWithUnknownAddresses);
  }

  getAuthorDistributions(names, emails, addresses, roles, decimals, share) {
    return getAuthorDistributions(
      names,
      emails,
      addresses,
      roles,
      decimals,
      share
    );
  }

  getDirects(
    authorsWithAddresses,
    researchContractDistributions,
    cap,
    multisigDirect,
    address
  ) {
    let total = BigNumber.from(0);
    let directs = authorsWithAddresses.map((author) => ({
      address: author.address,
      amount: author.amount,
    }));
    directs.forEach((direct) => {
      total = total.add(direct.amount);
    });

    const currentTotal = this._getCurrentTotal(
      directs,
      researchContractDistributions
    );
    const overflow = cap.sub(multisigDirect).sub(currentTotal);
    if (overflow < 0) {
      throw new Error(
        "Overflow in cap: " + cap.toString() + ", " + currentTotal.toString()
      );
    }

    multisigDirect = multisigDirect.add(overflow);
    if (multisigDirect.gt(0) && address != "") {
      directs.push({
        address: address,
        amount: multisigDirect,
      });
    }
    return directs;
  }

  _getCurrentTotal(directs, researchContractDistributions) {
    let currentTotal = BigNumber.from(0);
    directs.forEach((direct) => {
      currentTotal = currentTotal.add(direct.amount);
    });
    researchContractDistributions.forEach((distribution) => {
      currentTotal = currentTotal.add(distribution.amount);
    });
    return currentTotal;
  }

  getReferenceDistributions(
    urls,
    addresses,
    minters,
    percents,
    fraction,
    supply,
    decimals
  ) {
    return getReferenceDistributions(
      urls,
      addresses,
      minters,
      percents,
      fraction,
      supply,
      decimals
    );
  }

  async createMerkleDistributedToken({
    blockNumber,
    name,
    symbol,
    firstAuthors,
    middleAuthors,
    piAuthors,
    researchIdentifier,
    directs,
    distributions,
    references,
    paperMetadata,
    researchPortfolioGnosisAddress,
    chainNetwork,
  }: {
    blockNumber: BigNumber;
    name: string;
    symbol: string;
    firstAuthors: { name: string; role: string; amount: BigNumber }[];
    middleAuthors: { name: string; role: string; amount: BigNumber }[];
    piAuthors: { name: string; role: string; amount: BigNumber }[];
    researchIdentifier: string;
    directs: { address: string; amount: BigNumber }[];
    distributions: (
      | {
          amount: BigNumber;
          erc1155Token?: undefined;
          account: { address: string };
        }
      | {
          amount: BigNumber;
          erc1155Token: { address: string; tokenID: BigNumber };
          account?: undefined;
        }
    )[];
    references: {
      amount: BigNumber;
      url: string;
      address?: string;
      minter?: string;
    }[];
    paperMetadata: {
      title: string;
      abstract: string;
      authors: {
        amount: BigNumber;
        name: string;
        role: string;
      }[];
      publishedAt: string;
      doi?: string;
      field?: string[];
      venue?: {
        name: string;
        url?: string;
      };
    };
    researchPortfolioGnosisAddress: string;
    chainNetwork: string;
  }) {
    const RESEARCH_PORTFOLIO_GNOSIS_ADDRESS = researchPortfolioGnosisAddress;
    if (!RESEARCH_PORTFOLIO_GNOSIS_ADDRESS) {
      console.log("Research gnosis: ", RESEARCH_PORTFOLIO_GNOSIS_ADDRESS);
      throw new Error("RESEARCH_PORTFOLIO_GNOSIS_ADDRESS not set");
    }

    const suffix =
      chainNetwork === "goerli" || chainNetwork === "sepolia"
        ? "-" + (Math.random() + 1).toString(36).substring(7)
        : "";
    const contractResearchIdentifier =
      _optimizeIdentifier(researchIdentifier) + suffix;

    const referencesIPFS: {
      amount: string;
      researchIdentifier: string;
      address: string;
    }[] = [];

    const authors_: (
      | { amount: string; role: string; name: string }
      | {
          amount: string;
          role: string;
          name: string;
        }
    )[] = [];

    const { Entrypoint } = await this.loadContracts();
    const entrypoint = Entrypoint.connect(this.signer);

    var i = 1; // We start at 1 because the 0th is for the minter.
    for (const reference of references) {
      const recipient = reference.minter
        ? reference.minter
        : RESEARCH_PORTFOLIO_GNOSIS_ADDRESS;
      referencesIPFS.push({
        amount: reference.amount.toString(),
        address: recipient,
        researchIdentifier: reference.url,
      });
      i++;
    }

    for (const author of [...firstAuthors, ...middleAuthors, ...piAuthors]) {
      // NOTE: This is going into the IPFS, so we can read it from there.
      authors_.push({
        amount: author.amount.toString(),
        role: author.role,
        name: author.name,
      });
    }

    const { distributorBalance, distributorManifest, jsonResult, merkleRoot } =
      await this.apiClient._prepareDataForMinting(blockNumber, distributions);

    console.log("Distributor balance: ", distributorBalance);
    const paperInfoInput = {
      ...paperMetadata,
      references: referencesIPFS,
      authors: authors_,
    };
    const { cid } = await this.publishPaperInfoToIPFS(paperInfoInput);
    const metadata = cid;

    const directAddresses = directs.map(({ address }) => address);
    const directAmounts = directs.map(({ amount }) => amount.toString());

    const researchTokenInput = {
      name,
      symbol,
      researchIdentifier: contractResearchIdentifier,
      metadata,
    };
    const researchTokenDistribution = {
      manifest: distributorManifest,
      merkleRoot,
      amount: distributorBalance,
      // For token distributors, returnTokenAddress is the RP gnosis address.
      returnTokenAddress: RESEARCH_PORTFOLIO_GNOSIS_ADDRESS,
    };

    const directAmountSum = directAmounts.reduce(
      (total, amount) => total.add(BigNumber.from(amount)),
      BigNumber.from(0)
    );

    const txn = await entrypoint.createAndDistributeResearchToken(
      researchTokenInput,
      researchTokenDistribution,
      directAddresses,
      directAmounts,
      // // NOTE: gasLimit might not be necessary
      {
        gasLimit: 1000000, // 50k is too low for merkles
      }
    );

    const { token, distributor } = await eventArgsFromLog(
      txn,
      "CreateResearchTokenAndDistributor",
      true
    );

    if (distributor && jsonResult) {
      await this.apiClient.createTokenWithManifest(
        distributor,
        jsonResult,
        token
      );
    }

    return { txn, token };
  }

  async unclaimedForTokenQuery({
    tokenAddress,
    accountAddress,
  }: {
    tokenAddress: string;
    accountAddress: string;
  }) {
    return await this.apiClient.unclaimedForTokenQuery({
      tokenAddress,
      accountAddress,
    });
  }

  async claimUnclaimedForToken(tokenAddress: string) {
    console.log("tokenAddress: ", tokenAddress);
    const unclaimed = await this.apiClient.unclaimedForTokenQuery({
      tokenAddress,
      accountAddress: await this.signer.getAddress(),
    });
    return await Promise.all(
      unclaimed.map(({ distributor, index, amount, proof }) =>
        this.claimMerkleDistribution({ distributor, index, amount, proof })
      )
    );
  }

  async claimMerkleDistribution({
    distributor,
    index,
    amount,
    proof,
  }: {
    distributor: string;
    index: string;
    amount: string;
    proof: string[];
  }) {
    const { Entrypoint } = await this.loadContracts();
    const overrides = {
      gasLimit: 125000, // estimate.toNumber(), // You may add a buffer if needed
    };
    return Entrypoint.connect(this.signer).claimForAccountFromDistributorV2(
      distributor,
      index,
      amount,
      proof,
      overrides
    );
  }
}
