export default function Manifesto() {
  return (
    <div className="min-h-screen pt-10">
      <div className="mx-auto max-w-3xl">
        <div className="content-center">
          <div className="text-zinc-900 text-[40px] font-semibold leading-[54px]">
            Our Manifesto
          </div>
          <div className="text-neutral-600 text-base font-normal leading-relaxed">
            <p className="mt-2 mb-2">
              We are addressing problems in how research is funded. The first
              goal is to properly recognize and renumerate research. The second
              goal is to let anyone contribute money to impactful research,
              especially in under-funded areas.
            </p>
            <p className="mb-2">
              We are 1) rewarding the actual research artifact, e.g. paper or
              dataset, with 2) restrospective funding. By rewarding the
              artifact, it is trivial to make an index representing a
              researcher, lab, subject matter, or any other slice. By doing so
              retrospectively, funders can gauge what research to reward rather
              than predicting ahead of time, which is an impossible task.
            </p>
            <p className="mb-2">
              Note that this is how the system works already; we're just making
              it explicit. All scientists begin their career under a lab's
              principal investigator. After learning the craft, they use their
              body of work to secure a new position, like a postdoc, professor,
              or promotion within industry. Those positions come with
              kickstarter funding. In other words, the current system already
              bootstraps new researchers by retrospectively rewarding them for
              their work.
            </p>
            <p className="mb-2">
              We are augmenting this and eliminating inefficiencies that demand
              that researchers become marketers to get awarded grants that won't
              arrive for a year.
            </p>
          </div>
          <div className="text-zinc-900 text-2xl font-semibold leading-[43.20px] mt-5">
            Participating as a Researcher
            <div className="text-neutral-600 text-base font-normal leading-relaxed">
              <p className="mt-2 mb-2">
                Our main draw for researchers is that we set up the market to
                properly remunerate you for your work, and we do it such that
                you don't have to give us bank details or wait year(s) for those
                funds to arrive.
              </p>
              <p className="mt-2 mb-2">
                You mint a research artifact through our website, which then has
                its own supply of one million fungible tokens, the majority
                going to the authors. The rest of the tokens are distributed
                amongst the paper's important references, as decided by the
                minting author. Our service performs this proportional
                allocation and posts it on the Ethereum blockchain.
              </p>
              <p className="mt-2 mb-2">
                We will soon open this to the public, but we are currently in a
                closed alpha while we onboard early researchers and funders. If
                you would like to be in that group, please submit your interest
                on the home page.
              </p>
              <p className="mt-2 mb-2">
                In the future, we will have a service to easily send grants,
                donations, tips, or royalties to research papers. Donors will be
                distributing funds to the people whose work produced the paper
                based on their ownership share of that paper's tokens.
              </p>
            </div>
          </div>

          <div className="text-zinc-900 text-2xl font-semibold leading-[43.20px] mt-5">
            An Example
            <div className="text-neutral-600 text-base font-normal leading-relaxed">
              <p className="mt-2 mb-2">
                Alice is a researcher, she has published an impactful paper in a
                popular field. While her paper cites many other works, she'd
                like to specifically recognize the role of Bob and Carol's
                papers.
              </p>

              <p className="mt-2 mb-2">
                Ernest and Eve work at different institutions, but collaborate
                and wish to share credit. They split their minted tokens
                equally.
              </p>

              <p className="mt-2 mb-2">
                Frances runs a profitable business that applies the ideas from
                C1 and E1. She wants to share some of her revenue with the
                people that made her industry possible and have contributed to
                her business success.
              </p>

              <p className="mt-2 mb-2">
                Alice mints a paper A1 that cites two papers with equal weight:
                B1 from Bob and C1 from Carol. Ignoring B1 and C1's references,
                we have the following token balances:
              </p>

              <ol className="list-inside ml-5 list-decimal">
                <li>Alice: 87.5% of A1.</li>
                <li>Bob: 5% of A1, 87.5% of B1</li>
                <li>Carol: 5% of A1, 87.5% of C1</li>
              </ol>

              <p className="mt-2 mb-2">
                Ernest and Eve make equal contributions to a new paper, E1,
                which cites A1 with weight of 80% and C1 20%. The balances are
                now:
              </p>

              <ol className="list-inside ml-5 list-decimal">
                <li>Alice: 7% of E1, 87.5% of A1.</li>
                <li>Bob: 0.4% of E1, 5% of A1, 87.5% of B1</li>
                <li>Carol: 2.15% of E1, 5% of A1, 87.5% of C1</li>
                <li>Ernest: 43.75% of E1</li>
                <li>Eve: 43.75% of E1</li>
              </ol>

              <p className="mt-2 mb-2">
                Frances commits $1M / year to a liquidity pool for C1 and E1.
                The pool is configured to pay at least $1 per C1 or E1 token,
                implying that Gina believes that E1 and C1 are worth at least
                $1M each. The balances are now:
              </p>

              <ol className="list-inside ml-5 list-decimal">
                <li>Alice: 7% of E1 ($70,000), 89% of A1.</li>
                <li>Bob: 0.4% of E1 ($4,000), 5% of A1, 89% of B1</li>
                <li>Carol: 2.15% of E1 ($21,500), 5% of A1, 89% of C1</li>
                <li>Ernest: 43.75% ($437,500) of E1</li>
                <li>Eve: 43.75% ($437,500) of E1</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
