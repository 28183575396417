/*
Page for claiming funds from Amaranth Prize.
*/

import React, { useContext, useState, useEffect } from "react";
import { ClientContext } from "../helpers/context";
import { MessageBanner } from "../components";
import { BigNumber } from "ethers";
import { usePrivy, useWallets } from "@privy-io/react-auth";

export default function Amaranth({ track }) {
  const { getAccessToken, user } = usePrivy();
  const { wallets } = useWallets();
  const { client } = useContext(ClientContext);
  const [targetAddress, setTargetAddress] = useState("");
  const [wizardStep, setWizardStep] = useState(0);
  const [accountBalance, setAccountBalance] = useState(BigNumber.from(0));
  const [targetBalance, setTargetBalance] = useState(BigNumber.from(0));
  const [transferInitiated, setTransferInitiated] = useState(false);

  // Wizard flow.
  const numWizardSteps = 3;
  const WELCOME_STEP = 0;
  const CLAIM_STEP = 1;
  const WAIT_STEP = 2;
  const TRANSFER_STEP = 3;

  const address = user && client && client.address;
  console.log("Address: ", address);
  const [errorMsg, setErrorMsg] = useState("");

  let errorToast;
  if (errorMsg !== "") {
    errorToast = (
      <MessageBanner
        color="red"
        introString="Error with input: "
        message={errorMsg}
      />
    );
  } else {
    errorToast = null;
  }

  const validateInput = (input) => {
    const hexPattern = /^0x[0-9a-fA-F]{40}$/;
    if (!hexPattern.test(input)) {
      setErrorMsg(
        "An Ethereum wallet address must start with '0x' and contain 40 hexadecimal characters."
      );
      return false;
    } else {
      setErrorMsg("");
      return true;
    }
  };

  useEffect(() => {
    const updateBalance = async () => {
      if (address) {
        client.getBalance(address).then((b) => {
          setAccountBalance(b);
        });
      }
      if (
        targetAddress &&
        targetAddress.length === 42 &&
        targetAddress.startsWith("0x")
      ) {
        client.getBalance(targetAddress).then((b) => {
          setTargetBalance(b);
        });
      }
    };
    updateBalance();
  }, [address, targetAddress]);

  useEffect(() => {
    if (!client || !client.address) {
      setWizardStep(0);
    }
  }, [client]);

  const wizard = (wizardStep) => {
    if (wizardStep === WELCOME_STEP) {
      return welcomeScreen();
    } else if (wizardStep === CLAIM_STEP) {
      return claimScreen();
    } else if (wizardStep === WAIT_STEP) {
      return waitScreen();
    } else if (wizardStep === TRANSFER_STEP) {
      return transferScreen();
    } else {
      setErrorMsg("Invalid wizard step.");
      return welcomeScreen();
    }
  };

  const submitClaim = async (e) => {
    e.preventDefault();

    track("Amaranth", "attemptClaim", "try");

    const authToken = await getAccessToken();

    // TODO(dye): Validate that the claimRequest has non-zero funds. Otherwise show an error.
    await client
      .claimAmaranthPrize(authToken, targetAddress, address)
      .then((response) => {
        if (!response.ok) {
          setErrorMsg(
            "Received error response from server when attempting to claim: ",
            response.json
          );
        }
      })
      .catch((error) => {
        setErrorMsg("Error when attempting to submit claim: ", error.message);
      });
  };

  const submitTransfer = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    var transferReceipt;
    if (!validateInput(targetAddress)) {
      return;
    }

    try {
      console.log("Initiate transfer of balance to ", targetAddress);
      setTransferInitiated(true);
      transferReceipt = await client.transferFunds(
        targetAddress,
        accountBalance
      );
      console.log("Transfer receipt", transferReceipt);
    } catch (error) {
      console.log("Error when attempting to transfer funds: ", error);
      setErrorMsg("Error when attempting to transfer funds: ", error);
      setTransferInitiated(false);
    }
    if (!transferReceipt.status || transferReceipt.status !== 1) {
      setErrorMsg("Transfer transaction failed: ", transferReceipt);
      setTransferInitiated(false);
    }
  };

  const nextClick = async () => {
    setErrorMsg("");
    let nextWizardStep = wizardStep;
    switch (wizardStep) {
      case WELCOME_STEP:
        nextWizardStep = address ? wizardStep + 1 : wizardStep;
        break;
      case CLAIM_STEP:
        break;
      case WAIT_STEP:
        nextWizardStep = wizardStep + 1;
        break;
      case TRANSFER_STEP:
        var transferReceipt;
        try {
          console.log("Initiate transfer of balance to ", targetAddress);
          setTransferInitiated(true);
          transferReceipt = await client.transferFunds(
            targetAddress,
            accountBalance
          );
          console.log("Transfer receipt", transferReceipt);
        } catch (error) {
          console.log("Error when attempting to transfer funds: ", error);
          setErrorMsg("Error when attempting to transfer funds: ", error);
          setTransferInitiated(false);
        }
        if (!transferReceipt.status || transferReceipt.status !== 1) {
          setErrorMsg("Transfer transaction failed: ", transferReceipt);
          setTransferInitiated(false);
        }
        nextWizardStep = wizardStep;
        break;
      default:
        setErrorMsg("Invalid wizard step.");
        nextWizardStep = wizardStep;
    }
    setWizardStep(nextWizardStep);
    return;
  };

  const prevClick = () => {
    setErrorMsg("");
    setWizardStep(wizardStep - 1);
    return;
  };

  const prevButton = () => {
    const buttonStyle =
      "h-10 p-3.5 bg-zinc-900 bg-opacity-90 rounded-md border border-slate-200 justify-center items-center gap-2.5 text-white text-base font-semibold leading-normal inline-flex";
    return (
      <button className={buttonStyle} onClick={prevClick}>
        Back
      </button>
    );
  };

  const nextButton = () => {
    if (!client || !client.address) {
      return null;
    }

    const buttonStyle =
      "h-10 p-3.5 bg-blue-600 rounded-md justify-center items-center gap-2.5 inline-flex text-white text-base font-semibold leading-normal";
    var additionalStyle = "";

    console.log("Address: ", address);
    additionalStyle = address ? "" : "opacity-50 cursor-not-allowed";
    return (
      <button className={buttonStyle + additionalStyle} onClick={nextClick}>
        Next
      </button>
    );
  };

  // Wizard step 0: Welcome
  function welcomeScreen() {
    return (
      <div className="text-neutral-600 text-base font-normal leading-relaxed">
        <p className="mt-2 mb-2">
          <a
            href="https://www.amaranthprize.com"
            className="text-blue-600 text-base font-medium leading-relaxed"
          >
            {" "}
            The Amaranth Prize{" "}
          </a>{" "}
          rewards the best research in longevity. Our panel of experts reviewed
          the outstanding work published in recent years on the topic of protein
          aging within longevity. The prize is powered by our platform through
          which awards are distributed.
        </p>
        {!address && (
          <p className="mb-2">
            To claim your prize, please log in by clicking the blue Login button
            in the top right and following the instructions, using your email as
            the login.
          </p>
        )}
        <div className="flex items-right py-4 my-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
          {nextButton()}
        </div>
      </div>
    );
  }

  // Wizard step 1: Claim
  const claimScreen = () => {
    if (!accountBalance.eq(0)) {
      var roundedAccountBalance = accountBalance
        .div(BigNumber.from(10).pow(18))
        .toNumber()
        .toFixed(2);
      var roundedTargetBalance = targetBalance
        ? targetBalance.div(BigNumber.from(10).pow(18)).toNumber().toFixed(2)
        : "???";

      let submitTransferButtonStyle =
        "ml-auto h-10 p-3.5 bg-cyan-500 rounded justify-center items-center gap-2.5 inline-flex text-white text-base font-semibold leading-normal right-0 hover:from-teal-600 hover:to-cyan-700";
      if (transferInitiated) {
        submitTransferButtonStyle += " opacity-50 cursor-not-allowed";
      }

      return (
        <div className="text-neutral-600 text-base font-normal leading-relaxed">
          <p className="mt-2 mb-2">
            Your balance is ready to transfer! Input a destination Ethereum
            wallet, like a Coinbase Ethereum address. The wallet should start
            with "0x" and be a total of 42 characters. We'll check that before
            we process.
          </p>
          <p className="mt-2 mb-2">
            Then click "Transfer" to initiate the transfer of funds. Once the
            transfer has completed, refresh this page to see the new balance of
            your destination wallet reflected below. This can take 20-30
            minutes, so please be patient. Again, please do not repeat the
            claim. If you think there is an error, reach out to us at
            support@researchportfolio.co.
          </p>
          <div className="mt-6 flex justify-around items-center">
            <div className="text-center font-medium">
              This account's balance: {roundedAccountBalance.toString()}
            </div>
            <div className="text-center font-medium">
              Destination balance: {roundedTargetBalance.toString()}
            </div>
          </div>
          <div className="mt-2 flex justify-center items-center gap-5">
            <div className="flex items-right py-4 my-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              {prevButton()}
            </div>

            <input
              type="text"
              placeholder="Destination address"
              onChange={(e) => {
                setTargetAddress(e.target.value);
              }}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            />
            <button
              type="submit"
              onClick={submitTransfer}
              className={submitTransferButtonStyle}
            >
              Transfer
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-neutral-600 text-base font-normal leading-relaxed">
          <p className="mt-2 mb-2">
            Press the Claim button below to initiate the process. This will send
            the money to your Ethereum wallet and can take up to ten minutes.
            When that completes, you will be able to redirect the funds to
            another wallet.
          </p>
          <p>
            If you have already done the claim, please do not do repeat it. It
            will not speed up the process. If you think there is an error, reach
            out to us at support@researchportfolio.co.
          </p>
          <div className="mt-2 flex justify-between items-center gap-5">
            {prevButton()}

            <button
              type="submit"
              onClick={submitClaim}
              className="ml-4 px-4 py-3 bg-cyan-500 text-white rounded-md hover:from-teal-600 hover:to-cyan-700"
            >
              Claim
            </button>
          </div>
        </div>
      );
    }
  };

  // Overall Amaranth page layout.
  return (
    <div className="min-h-screen pt-10">
      <div className="mx-auto max-w-3xl">
        <div className="content-center">
          {errorToast}
          <div className="text-zinc-900 text-[40px] font-semibold leading-[54px]">
            The Amaranth Prize
          </div>
          {wizardStep < numWizardSteps ? (
            <div className="w-[640px] text-gray-500 text-[11px] font-semibold uppercase leading-normal tracking-tight">
              Step {wizardStep + 1}/{numWizardSteps}
            </div>
          ) : null}
          {wizard(wizardStep)}
        </div>
      </div>
    </div>
  );
}
