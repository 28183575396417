import React from "react";
import { MessageBanner } from "../components";

export default function ClaimFail({ info, onClose }) {
  const introString = "Claim Fail!";
  const color = "yellow";
  const message = <p className="text-sm">{info}</p>;
  return (
    <MessageBanner
      color={color}
      introString={introString}
      message={message}
      onClose={onClose}
    />
  );
}
