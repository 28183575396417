import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import "./App.css";
import { PrivyProvider } from "@privy-io/react-auth";
import { mainnet, goerli, sepolia } from "@wagmi/chains";

import { ClientProvider } from "./helpers/context";
import { Account, Footer, Header } from "./components";
import {
  Artifact,
  Amaranth,
  Faq,
  Landing,
  Manifesto,
  Mint,
  Research,
} from "./views";

function App() {
  const analyticsTrackingId = process.env.ANALYTICS_TRACKING_ID;

  useEffect(() => {
    if (analyticsTrackingId) {
      const analyticsDebug = process.env.CHAIN_NETWORK != "mainnet";
      ReactGA.initialize(analyticsTrackingId, {
        testMode: analyticsDebug,
      });
    }
  }, [analyticsTrackingId]);

  const track = (category, action, label) => {
    if (analyticsTrackingId) {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
      });
    }
  };

  const { pathname } = useLocation();
  useEffect(() => {
    if (analyticsTrackingId) {
      ReactGA.send({ hitType: "pageview", page: pathname, title: pathname });
    }
  }, [pathname]);

  const handleLogin = () => {};

  let chain;
  switch (process.env.CHAIN_NETWORK) {
    case "mainnet":
      chain = mainnet;
      break;
    case "goerli":
      chain = goerli;
      break;
    case "sepolia":
      chain = sepolia;
      break;
    default:
      break;
  }

  return (
    <PrivyProvider
      appId={process.env.PRIVY_APP_ID}
      onSuccess={handleLogin}
      config={{
        loginMethods: ["wallet", "email"],
        showWalletLoginFirst: false,
        embeddedWallets: {
          noPromptOnSignature: true,
        },
        defaultChain: chain,
        supportedChains: [chain],
        appearance: {
          theme: "light",
          accentColor: "#06b6d4",
          logo: "https://www.researchportfolio.co/briefcase.2x.ico",
        },
      }}
    >
      <ClientProvider>
        <div className="sm:px-2.5 mx-auto">
          <div className="background-main background-gradient ">
            <Header track={track} account={<Account />} />
            <div className="relative overflow-hidden">
              <main>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Landing track={track} />}
                  ></Route>
                  <Route path="/faq" element={<Faq track={track} />} />
                  <Route path="/manifesto" element={<Manifesto />} />
                  <Route path="/mint" element={<Mint track={track} />} />
                  <Route
                    path="/amaranth-prize"
                    element={<Amaranth track={track} />}
                  />
                  <Route
                    path="/research/:artifactId"
                    element={<Artifact track={track} />}
                  />
                  <Route
                    path="/research"
                    element={<Research track={track} />}
                  />
                </Routes>
              </main>
            </div>
            <Footer />
          </div>
        </div>
      </ClientProvider>
    </PrivyProvider>
  );
}

export default App;
