export function isValidHttpUrl(s) {
  try {
    const url = new URL(s);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
}

export function isValidEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function timeConverter(timestamp, withHours) {
  var a = new Date(timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + " " + month + " " + year;
  if (withHours) {
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    time = time + " " + hour + ":" + min + ":" + sec;
  }
  return time;
}

const stopwords = [
  "i",
  "me",
  "my",
  "myself",
  "we",
  "our",
  "ours",
  "ourselves",
  "you",
  "your",
  "yours",
  "yourself",
  "yourselves",
  "he",
  "him",
  "his",
  "himself",
  "she",
  "her",
  "hers",
  "herself",
  "it",
  "its",
  "itself",
  "they",
  "them",
  "their",
  "theirs",
  "themselves",
  "what",
  "which",
  "who",
  "whom",
  "this",
  "that",
  "these",
  "those",
  "am",
  "is",
  "are",
  "was",
  "were",
  "be",
  "been",
  "being",
  "have",
  "has",
  "had",
  "having",
  "do",
  "does",
  "did",
  "doing",
  "a",
  "an",
  "the",
  "and",
  "but",
  "if",
  "or",
  "because",
  "as",
  "until",
  "while",
  "of",
  "at",
  "by",
  "for",
  "with",
  "about",
  "against",
  "between",
  "into",
  "through",
  "during",
  "before",
  "after",
  "above",
  "below",
  "to",
  "from",
  "up",
  "down",
  "in",
  "out",
  "on",
  "off",
  "over",
  "under",
  "again",
  "further",
  "then",
  "once",
  "here",
  "there",
  "when",
  "where",
  "why",
  "how",
  "all",
  "any",
  "both",
  "each",
  "few",
  "more",
  "most",
  "other",
  "some",
  "such",
  "no",
  "nor",
  "not",
  "only",
  "own",
  "same",
  "so",
  "than",
  "too",
  "very",
  "s",
  "t",
  "can",
  "will",
  "just",
  "don",
  "should",
  "now",
];
export function removeStopwords(str) {
  let res = [];
  const words = str.split(" ");
  for (var i = 0; i < words.length; i++) {
    let clean = words[i].split(".").join("");
    if (!stopwords.includes(clean)) {
      res.push(clean);
    }
  }
  return res.join(" ");
}

// Can only retrieve arxiv atm. TODO: Add to this when we can get more.
export function canRetrieveResearchUrl(url) {
  if (/(.*arxiv.org)\/(.*)\/([0-9]{4}.[0-9]{5}).*/.test(url)) {
    return true;
  }
  if (/(.*biorxiv.org.*\d{6})(v[0-9])?(.*)/.test(url)) {
    return true;
  }
  if (/(.*medrxiv.org.*\d{8})(v[0-9])?(.*)/.test(url)) {
    return true;
  }
  return false;
}

function _getArxivGroupsFromString(str) {
  const doiMatch = str.match(/arxiv:([0-9]{4}.[0-9]{5})/);
  if (doiMatch) {
    return doiMatch;
  }

  return str.match(/(.*arxiv.org)\/(.*)\/([0-9]{4}.[0-9]{5}).*/);
}

function _getBiorxivGroupsFromString(str) {
  const doiMatch = str.match(
    /biorxiv:([0-9]{2}.[0-9]{4}\/[0-9]{4}.[0-9]{2}.[0-9]{2}.[0-9]{6})/
  );
  if (doiMatch) {
    return doiMatch;
  }

  return str.match(/(.*biorxiv.org.*\d{5})(v[0-9])?(.*)/);
}

function _getMedrxivGroupsFromString(str) {
  const doiMatch = str.match(
    /medrxiv:([0-9]{2}.[0-9]{4}\/[0-9]{4}.[0-9]{2}.[0-9]{2}.[0-9]{8})/
  );
  if (doiMatch) {
    return doiMatch;
  }

  return str.match(/(.*medrxiv.org.*\d{8})(v[0-9])?(.*)/);
}

function _getGroupsFromString(str) {
  const arxivMatch = _getArxivGroupsFromString(str);
  if (arxivMatch) {
    return arxivMatch;
  }

  const biorxivMatch = _getBiorxivGroupsFromString(str);
  if (biorxivMatch) {
    return biorxivMatch;
  }

  const medrxivMatch = _getMedrxivGroupsFromString(str);
  if (medrxivMatch) {
    return medrxivMatch;
  }

  return null;
}

function _getSubmissionTypeFromGroups(groups) {
  const firstGroup = groups[0];
  if (firstGroup.indexOf("arxiv") > -1) {
    return "arxiv";
  } else if (firstGroup.indexOf("biorxiv") > -1) {
    return "biorxiv";
  } else if (firstGroup.indexOf("medrxiv") > -1) {
    return "medrxiv";
  }

  return null;
}

function _getUrlForMintSuccess(submissionType, groups) {
  if (submissionType.toLowerCase() === "arxiv") {
    return "https://arxiv.org/abs/" + groups[3];
  } else if (submissionType.toLowerCase() == "biorxiv") {
    return "https://www.biorxiv.org/content/" + groups[3];
  } else if (submissionType.toLowerCase() == "medrxiv") {
    return "https://www.medrxiv.org/content/" + groups[3];
  }

  return null;
}

export function getMessageStringForMintSuccess(researchUrl, token) {
  return "To confirm this token, please have the authors contact us by email from the paper's listed point of contact.";
}

// Currently, this is just ensuring that if a user puts in a PDF arxiv link that it changes to an abs link.
// Future plans will be to fix other potential issues that arise.
export function fixResearchUrlInput(value) {
  // https://arxiv.org/[abs|pdf]/1807.06919
  const arxivParts = value.match(/(.*arxiv.org)\/(.*)\/([0-9]{4}.[0-9]{5}).*/);
  if (arxivParts) {
    return arxivParts ? arxivParts[1] + "/abs/" + arxivParts[3] : value;
  }

  // https://www.biorxiv.org/content/10.1101/2020.10.13.337881v2[.full.pdf|.article-info]
  const biorxivParts = value.match(/(.*biorxiv.org.*\d{5})(v[0-9])?(.*)/);
  if (biorxivParts) {
    return biorxivParts[1].replace("www.", "");
  }

  // https://www.medrxiv.org/content/10.1101/2022.01.31.21267382v3.article-info
  const medrxivParts = value.match(/(.*medrxiv.org.*\d{8})(v[0-9])?(.*)/);
  if (medrxivParts) {
    return medrxivParts[1].replace("www.", "");
  }

  return value;
}

export function optimizeIdentifier(researchIdentifier) {
  const arxivRegex = /^.*arxiv.org\/(abs|pdf)\/(\d{4}\.\d{5}).*/;

  const arxivCheck = arxivRegex.exec(researchIdentifier);
  if (arxivCheck) {
    return "arxiv:" + arxivCheck[2];
  } else {
    return researchIdentifier;
  }
}
