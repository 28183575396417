import React, { useContext } from "react";
import Modal from "./Modal";
import { switchNetwork } from "../helpers/wallet";
import { ClientContext } from "../helpers/context";

export function NetworkDisplayPopup() {
  const { client } = useContext(ClientContext);
  const selectedChainId = client.selectedChainId;
  const targetNetwork = client.targetNetwork;

  const getNameFromNetwork = (network) => {
    if (!network || !network.name) {
      return "Local";
    }

    if (network.name === "homestead") {
      return "Mainnet";
    }

    return network.name[0].toUpperCase() + network.name.slice(1);
  };

  let networkDisplay = "";
  if (selectedChainId && parseInt(selectedChainId) !== targetNetwork.chainId) {
    const description = (
      <p>
        We use the <b>{getNameFromNetwork(targetNetwork)}</b> network. You're on{" "}
        a different chain.{" "}
        <button
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          onClick={() => switchNetwork(targetNetwork)}
        >
          Click here to switch
        </button>
        .
      </p>
    );
    networkDisplay = (
      <div className="pb-6">
        <Modal
          title={"Wrong Network"}
          description={description}
          closeText={"Close"}
        />
      </div>
    );
  }

  return networkDisplay;
}

export function NetworkDisplayButton({ selectedNetwork, targetNetwork }) {
  let networkDisplay = null;
  if (selectedNetwork && selectedNetwork.chainId !== targetNetwork.chainId) {
    networkDisplay = (
      <button
        className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={async () => switchNetwork(targetNetwork)}
      >
        Switch network to{" "}
        {targetNetwork && targetNetwork.name ? targetNetwork.name : "local"}
      </button>
    );
  }

  return networkDisplay;
}
