import React from "react";

export default function Footer() {
  return (
    <footer aria-labelledby="footer-heading">
      <div className="flex justify-between max-w-7xl mx-auto">
        <p className="text-center footer-copyright">
          &copy; 2023 Research Portfolio. All rights reserved.
        </p>
        <div className="flex gap-x-3">
          <a
            key="email"
            href={"mailto:support@researchportfolio.co"}
            className="footer-link hover:text-black"
          >
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
}
