/*
Card for displaying a research token. Lets users claim here too.
*/

import React from "react";
import { BriefcaseIcon } from "@heroicons/react/outline";

import { AddToWallet, Copy, HeldBalance } from "../components";
import { addToWallet } from "../helpers/wallet";

import { NETWORK } from "../constants";

function ClaimButton({ claim, balance }) {
  const tooltipClass =
    "tooltip mt-2 absolute w-1/5 rounded z-10 inline-block shadow-sm text-white p-1 bg-gray-900 opacity-sm duration-300 transition-opacity invisible font-medium py-2 px-3";
  const tooltipText = "Click to initiate your claim.";
  if (!balance || !claim) {
    return (
      <div className="flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 cursor-not-allowed">
        <BriefcaseIcon
          className="w-5 h-5 text-gray-700 cursor-pointer"
          aria-hidden="true"
        />
        <button disabled className="ml-3 claim-button disabled">
          {" "}
          {balance}
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
        <div className="has-tooltip">
          <BriefcaseIcon
            className="w-5 h-5 cursor-pointer"
            style={{ color: "#52c41a" }}
            aria-hidden="true"
          />
          <span className={tooltipClass}>{tooltipText}</span>
        </div>
        <button
          className="ml-1 claim-button font-medium hover:text-gray-500"
          onClick={() => claim()}
        >
          {" "}
          {balance}
        </button>
      </div>
    );
  }
}

export default function PrizeCard({
  client,
  token,
  setClaimError,
  setClaimSuccess,
  track,
}) {
  const { address, symbol, name, decimals, heldBalance, claimBalance } = token;

  const etherscanAddressUrl = NETWORK.blockExplorer + "address/" + address;

  return (
    <li
      key={address}
      className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center justify-between space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {name ? name : "Name of token"}
            </h3>
            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              <a
                className="etherscan-anchor"
                name="etherscan-anchor"
                href={etherscanAddressUrl}
                target="_blank"
              >
                {symbol ? symbol : "symbol"}
              </a>
            </span>
          </div>
          <div className="flex items-start justify-start space-x-3">
            <Copy text={address} message={"Copy Address"} />
            <AddToWallet
              address={address}
              symbol={symbol}
              decimals={decimals}
            />
          </div>
        </div>
      </div>
      <div
        className="-mt-px flex divide-x divide-gray-200"
        style={{ marginTop: "auto" }}
      >
        {heldBalance ? (
          <div className="-ml-px w-0 flex-1 flex">
            <HeldBalance
              balance={heldBalance}
              addressUrl={etherscanAddressUrl}
              aClass={
                "own-button relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              }
              iconClass={"w-5 h-5 text-blue-400"}
              iconStyle={{ color: "text-blue-400" }}
              key="own"
            />
          </div>
        ) : null}
        {claimBalance ? (
          <div className="-ml-px w-0 flex-1 flex">
            <ClaimButton
              balance={claimBalance}
              claim={
                client && client.claimUnclaimedForToken
                  ? () => {
                      track("PrizeCard", "claimUnclaimedTokenTry", address);
                      client
                        .claimUnclaimedForToken(address)
                        .then((txns) => {
                          addToWallet(address, symbol, decimals);
                          track(
                            "PrizeCard",
                            "claimUnclaimedTokenSuccess",
                            address
                          );

                          setClaimSuccess({
                            message:
                              "Successfully claimed! We will update here within 15 minutes. Please do not reclaim before then.",
                          });
                        })
                        .catch((error) => {
                          let message = "There was an error claiming";
                          if (error.message) {
                            message += ": " + error.message;
                          } else {
                            message += ".";
                          }
                          track(
                            "PrizeCard",
                            "claimUnclaimedTokenFail",
                            address
                          );
                          track(
                            "PrizeCard",
                            "claimUnclaimedTokenError",
                            message
                          );
                          setClaimError(message);
                        });
                    }
                  : undefined
              }
              key="claim"
            />
          </div>
        ) : null}
      </div>
    </li>
  );
}
