import { Contract } from "ethers";

export async function loadContracts() {
  const url = process.env.DEPLOYED_CONTRACTS_URL || "/api/deployedContracts";
  const response = await fetch(url);
  if (response.status !== 200) {
    throw Error(
      `non-200 status while fetching contracts status=${response.status}`
    );
  }

  // This is changed now that we have the deployed contracts in the API.
  const deployedText = await response.json();
  const deployed = JSON.parse(deployedText.data);

  const allDeployedContracts = deployed;
  const deployedChainId = Object.keys(allDeployedContracts)[0];
  const deployedChainNetwork = Object.keys(
    allDeployedContracts[deployedChainId]
  )[0];
  const contracts /*: Record<string, {address: string; abi: any}>*/ =
    allDeployedContracts[deployedChainId][deployedChainNetwork].contracts;

  const contractEntries /*: Record<string, Contract>*/ = objectFromEntries(
    Object.entries(contracts).map(([name, { address, abi }]) => [
      name,
      new Contract(address, abi),
    ])
  );
  const Entrypoint = contractEntries.Entrypoint;
  return { deployed, Entrypoint };
}

// <K extends string, V>(entries: Array<[K, V]>): Record<K, V> {
export function objectFromEntries(entries) {
  let o = {};
  for (const [k, v] of entries) {
    o[k] = v;
  }
  return o;
}
