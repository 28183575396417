import React, { useContext, useState } from "react";

import { isValidEmail } from "../helpers/format";
import { ClientContext } from "../helpers/context";

export default function Landing({ track }) {
  const [email, setEmail] = useState("");
  const [papers, setPapers] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const { client } = useContext(ClientContext);

  async function attemptAirtablePublish(email, papers) {
    let fields = { email: email };
    if (papers) {
      fields["papers"] = papers;
    }

    await client
      .publishAirtableInterest(fields)
      .then((response) => {
        setSubscribed(response);
        track("Landing", "publishAirtable", "success");
      })
      .catch((error) => {
        track("Landing", "publishAirtable", "fail");
        track("Landing", "publishAirtableError", error);
        console.log(error);
      });
  }

  const onAirtableSubmit = (e, email, papers) => {
    e.preventDefault();
    attemptAirtablePublish(email, papers);
  };

  const onInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case "email":
        setEmail(value);
        setSubscribed(false);
        break;
      case "papers":
        setPapers(value);
        setSubscribed(false);
        break;
      default:
        break;
    }
  };

  const subscribeText = subscribed
    ? "Thanks for subscribing! We will contact you soon."
    : "Submit your info and we will reach out to mint your papers.";
  const buttonText = subscribed ? "Got it!" : "Submit paper";
  const disabled = subscribed || !isValidEmail(email);
  const pointerClass = disabled ? "cursor-default" : "cursor-pointer";

  return (
    <div className="min-h-screen pt-10">
      <div className="mx-auto max-w-7xl">
        <div className="content-center">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:flex lg:items-center">
            <div className="lg:py-24">
              <h1 className="font-semibold text-5xl leading-[54px] mb-7 mt-4 text-4xl tracking-tight font-semibold sm:mt-5 lg:mt-6">
                <span className="block bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-cyan-500">
                  Research Funding
                </span>
                <span className="block text-zinc-900">
                  Designed for the Future
                </span>
                <span className="block text-sm font-normal">
                  Supported by Longevity Prize
                </span>
              </h1>
              <p className="text-base text-neutral-600 font-normal leading-relaxed">
                Research Portfolio builds tools for researchers to mint and
                trade tokens representing their research output. We are creating
                a new funding model for scientists, journalists, historians, and
                anyone else adding to the collective search for truth. This
                model augments existing approaches.
              </p>
              <p className="mt-11 text-zinc-900 text-[11px] font-semibold leading-normal tracking-tight">
                {subscribeText}
              </p>
              <div className="mt-4">
                <form
                  action="#"
                  onSubmit={(e) => onAirtableSubmit(e, email, papers)}
                  className="mx-3"
                >
                  <div className="grid grid-cols-1 sm:grid-cols-12 gap-y-4">
                    <div className="rounded border border-slate-200 col-span-12">
                      <input
                        id="papers"
                        name="papers"
                        type="papers"
                        onChange={onInputChange}
                        value={papers}
                        placeholder="Paper name, e.g. `Is Peer Review a Good Idea?`"
                        className="block w-full px-4 py-3 rounded-md border-0 text-neutral font-normal text-[15px] leading-normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                      />
                    </div>
                    <div className="rounded border border-slate-200 col-span-12">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={onInputChange}
                        value={email}
                        placeholder="Your email address"
                        className="block w-full px-4 py-3 rounded-md border-0 text-neutral font-normal text-[15px] leading-normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                      />
                    </div>
                    <div className="col-start-5 col-span-4 rounded justify-center items-center">
                      <button
                        disabled={disabled}
                        type="submit"
                        className={`${pointerClass} block w-full py-3 px-4 rounded-md bg-cyan-500 text-white font-medium hover:from-teal-600 hover:to-cyan-700`}
                      >
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
