import React from "react";
import { usePrivy, useWallets } from "@privy-io/react-auth";

import Address from "./Address";

export default function Account() {
  const { user, linkWallet, login, logout, connectWallet } = usePrivy();
  const { wallets } = useWallets();

  const handleLogout = () => {
    logout();
  };

  // TODO: Switch to using wallet.loginOrLink().

  if (!user) {
    return (
      <button
        type="button"
        id="web3SSO"
        className="inline-flex items-center sm:px-1 sm:py-0 md:px-4 md:py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-cyan-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={login}
      >
        Login
      </button>
    );
  } else if (!wallets.length) {
    return (
      <button
        type="button"
        id="web3SSO"
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-cyan-400 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={connectWallet}
      >
        Connect Wallet
      </button>
    );
  } else {
    let wallet;

    for (var i = 0; i < wallets.length; i++) {
      if (wallets[i].connectorType === "embedded") {
        wallet = wallets[i];
        break;
      }
    }

    if (!wallet) {
      wallet = wallets[0];
    }

    const ensProvider = wallet.getEthersProvider();
    return (
      <Address
        address={wallet.address}
        ensProvider={ensProvider}
        logout={handleLogout}
      />
    );
  }
}
