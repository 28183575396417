import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const faqs = {
  How: [
    {
      question: "Can you give me an example of how this works?",
      answer: (
        <div>
          <ul className="list-inside list-disc">
            <li>
              Alice is a researcher, she has published an impactful paper in a
              popular field. While her paper cites many other works, she'd like
              to specifically recognize the role of Bob and Carol's papers.
            </li>
            <li>
              Ernest and Eve work at different institutions, but collaborate and
              wish to share credit. They split their minted tokens equally.
            </li>
            <li>
              Frances runs a profitable business that applies the ideas from C1
              and E1. She wants to share some of her revenue with the people
              that made her industry possible and have contributed to her
              business success.
            </li>
            <li>
              Alice mints a paper A1 that cites two papers with equal weight: B1
              from Bob and C1 from Carol. Ignoring B1 and C1's references, we
              have the following token balances:
              <ol className="list-inside ml-5 list-decimal">
                <li>Alice: 87.5% of A1.</li>
                <li>Bob: 5% of A1, 87.5% of B1</li>
                <li>Carol: 5% of A1, 87.5% of C1</li>
              </ol>
            </li>
            <li>
              Ernest and Eve make equal contributions to a new paper, E1, which
              cites A1 with weight of 80% and C1 20%. The balances are now:
              <ol className="list-inside ml-5 list-decimal">
                <li>Alice: 7% of E1, 87.5% of A1.</li>
                <li>Bob: 0.4% of E1, 5% of A1, 87.5% of B1</li>
                <li>Carol: 2.15% of E1, 5% of A1, 87.5% of C1</li>
                <li>Ernest: 43.75% of E1</li>
                <li>Eve: 43.75% of E1</li>
              </ol>
            </li>
            <li>
              Frances commits $1M / year to a liquidity pool for C1 and E1. The
              pool is configured to pay at least $1 per C1 or E1 token, implying
              that Gina believes that E1 and C1 are worth at least $1M each. The
              balances are now:
              <ol className="list-inside ml-5 list-decimal">
                <li>Alice: 7% of E1 ($70,000), 89% of A1.</li>
                <li>Bob: 0.4% of E1 ($4,000), 5% of A1, 89% of B1</li>
                <li>Carol: 2.15% of E1 ($21,500), 5% of A1, 89% of C1</li>
                <li>Ernest: 43.75% ($437,500) of E1</li>
                <li>Eve: 43.75% ($437,500) of E1</li>
              </ol>
            </li>
          </ul>
        </div>
      ),
    },
    {
      question: "Why are you using Web3?",
      answer: (
        <span>
          The core reason is because there has been so much infrastructure built
          to quickly onboard new tokens and immediately provide market-making
          services. After minting your paper through our site, you can
          immediately accept payments on behalf of your completed work as well
          as transferring or selling your tokens to others. Downsides like the
          cost of transaction are short term issues that would be worse with
          other solutions.
        </span>
      ),
    },
    {
      question: "Can't anyone mint a research token on your site?",
      answer:
        "Yes! Anyone can mint any number of research tokens for anything they want. However, each paper " +
        "only has a single canonical token associated with it via the author's corresponding the DOI with the " +
        "contract address.",
    },
    {
      question: "Why does a research token represent the matching paper?",
      answer:
        "Before we recognize a token as legitimate, we require that authors verify " +
        "the research. There are two ways to do that. The primary way is to have " +
        "an email exchange with us from the paper's listed point of contact. " +
        "This will provide DKIM signatures that we can publicly publish with a " +
        "zero-knowledge proof. The second way is to include the contract address on " +
        "the canonical URL for the research. On Arxiv, this would be in the comments section. Similarly, each " +
        "token includes the DOI of its corresponding paper. In this way, we can match up the correct contract " +
        "with the paper so that only the author-sanctioned tokens are given provenance.",
    },

    {
      question: "Can I mint a token without a corresponding research artifact?",
      answer:
        "While nothing in our approach inhibits doing that, Research Portfolio is not a good fit for ongoing " +
        "ventures and we strongly discourage trying to shoehorn it. We do encourage artifacts like software or " +
        "datasets.",
    },
    {
      question: "Can I mint a token for my friend's paper?",
      answer:
        "We encourage authors themselves to mint the paper because the process is smoother and has more " +
        "community support. However, we will respect any morally sound process that results in research being " +
        "minted with due provenance on the matching website.",
    },
  ],
  Researchers: [
    {
      question: "Why should a researcher participate?",
      answer:
        "Our main draw for researchers is that we set up the market to properly remunerate you for " +
        "your work, and we do it such that you don't have to give us bank details or wait year(s) for those " +
        "funds to arrive.",
    },
    {
      question: "How does this work in practice for a researcher?",
      answer:
        "You mint a research artifact through our website. That paper has its own supply of one " +
        "million tokens, the majority going to the authors. The rest of the tokens are distributed " +
        "amongst the paper's important references, as decided by the minting author, and the greater community. " +
        "Our service performs this proportional allocation and posts it on the Ethereum blockchain.",
    },
    {
      question: "Can I tip research right now?",
      answer:
        "We have a coming service to send grants, donations, tips, or royalties to research papers. " +
        "By doing so, you will be distributing funds to the people whose work produced the paper based on their " +
        "ownership share of that paper's tokens.",
    },
    {
      question: "How do I mint my research?",
      answer:
        "Head over to the Mint page and use our wizard to complete the process. " +
        "Afterwards, contact us at support@researchportfolio.co from the same " +
        "email that's listed as the point of contact for the paper.",
    },
    {
      question: "Can I mint a token for a paper that I've already published?",
      answer:
        "Yes. Just update the paper's canonical URL to include a reference to the contract address. If " +
        "this is impossible, please contact us and we will find a way to recognize your contribution to the " +
        "pursuit of truth.",
    },
  ],
  Philanthropists: [
    {
      question: "Why should a philanthropist participate?",
      answer:
        "Our main draw for philanthropists is that we can satisfy any utility function in your " +
        "philanthropy. If you want to guide funding towards Longevity + CRISPR research, we can help you " +
        "bring that to fruition. If you want to further reward the top 10 works from researchers in " +
        "underserved communities, we can do that too.",
    },
    {
      question:
        "How can I influence research directions if it's retrospective?",
      answer:
        "Funding slightly obscure research directions today like Longevity or AI Alignment requires issuing RFPs, " +
        "sifting through all of the applicants, and curating which among them are worthwhile. In our system, funders " +
        "instead announce an annual $1m prize each year that will be distributed to the top (or all) papers " +
        "representing the field or challenge question they care about. In this way, they influence the direction " +
        "of research but do not stifle the researchers themselves.",
    },
    {
      question: "Can I support research right now?",
      answer:
        "We have a coming service to send grants donations, or royalties to research papers, and that " +
        "will reach the authors. If you would like to have a much larger impact, please contact us and we will " +
        "work with you directly.",
    },
    {
      question: "How does this work in practice for a philanthropist?",
      answer:
        "You can support a paper by using our interface to send USDC to that paper, which would be " +
        "efficiently dispersed amongst the paper's token holders. If you would like to do this en masse, contact " +
        "us for special tooling.",
    },
  ],
  Meta: [
    {
      question: "What are you trying to accomplish?",
      answer:
        "We are addressing problems in how research is funded. The first goal is to properly recognize and " +
        "renumerate research. The second goal is to let anyone contribute money to " +
        "impactful research, especially in under-funded areas.",
    },
    {
      question: "How does this work at a high level?",
      answer:
        "We are 1) rewarding the actual research artifact, e.g. paper or dataset, with 2) " +
        "restrospective funding. By rewarding the artifact, it is trivial to make an index representing a " +
        "researcher, lab, subject matter, or any other slice. By doing so retrospectively, funders can " +
        "gauge what research to reward rather than predicting ahead of time, an impossible task.",
    },
    {
      question:
        "How do you onboard new ideas and researchers when funding is retrospective?",
      answer:
        "All scientists begin their career under a lab's principal investigator. After learning the craft, " +
        "they use their body of work to secure a new position, like a postdoc, professor, or promotion within industry. " +
        "Those positions come with kickstarter funding. In other words, the current system " +
        "already bootstraps new researchers and retrospectively rewards them for their work. We are " +
        "augmenting this and eliminating inefficiencies demanding that researchers become marketers to " +
        "get awarded grants that won't arrive for a year.",
    },
  ],
  Value: [
    {
      question:
        "Do token holders have control or rights to the ideas described in a paper?",
      answer:
        "No. Holding research tokens does not grant any rights to the underlying intellectual property.",
    },
    {
      question: "Do tokens represent shares in a common enterprise?",
      answer:
        "No. Tokens represent a share of a completed artifact. These artifacts (papers, etc) do not change " +
        "after publishing and do not directly benefit from the future efforts of their authors. Grants or funds " +
        "received by token holders are unsolicited and uncoordinated.",
    },
    {
      question: "What do I get by owning tokens of minted research?",
      answer:
        "You get a prorata share of any funds sent to that completed research contract, as well as a prorata share of " +
        "any allocation from child research that referenced this one as important.",
    },
    {
      question: "Why will a research token have dollar value?",
      answer:
        "There are three ways. First, there is economic value when a token receives funding. That " +
        "funding go straight to the token holders. Second, there is economic value assigned to impactful papers " +
        "when the papers that reference them receive funding. Finally, there is speculative value - people want " +
        "to own GPT3 and others because they're notable.",
    },
  ],
};

function FaqButton({ selection, value, setSelected, text }) {
  let className =
    "text-gray-500 text-xs font-medium leading-normal tracking-tight";
  if (selection === value) {
    className =
      "px-2 py-2.5 bg-blue-600 bg-opacity-0 rounded-[3px] border border-blue-600 justify-center items-center text-blue-600 text-xs font-semibold leading-normal tracking-tight";
  }
  return (
    <button className={className} onClick={() => setSelected(value)}>
      {text}
    </button>
  );
}

export default function Faq({ track }) {
  const [selected, setSelected] = useState("all");

  const filterQuestions = () => {
    switch (selected) {
      case "all":
        return faqs.Meta.concat(faqs.How)
          .concat(faqs.Value)
          .concat(faqs.Researchers)
          .concat(faqs.Philanthropists);
      case "researchers":
        return faqs.Researchers;
      case "philanthropists":
        return faqs.Philanthropists;
      case "how":
        return faqs.How;
      default:
        return faqs.Meta.concat(faqs.How);
    }
  };

  const filteredQuestions = filterQuestions();

  return (
    <div className="min-h-screen pt-10">
      <div className="mx-auto max-w-3xl">
        <div className="content-center">
          <div className="text-zinc-900 text-[40px] font-semibold leading-[54px]">
            Frequently Asked Questions
          </div>
          <p className="mt-2 text-neutral-600 text-base font-normal leading-relaxed">
            Do you have more questions? Talk to us on{" "}
            <a
              href="https://www.twitter.com/researchfolio"
              className="text-blue-600 text-base font-medium leading-relaxed"
              target="_blank"
            >
              Twitter
            </a>
            .
          </p>
          <div className="mt-9 flex flex-row gap-x-5">
            <FaqButton
              selection={selected}
              value={"all"}
              setSelected={setSelected}
              text={"All Questions"}
            />

            <FaqButton
              selection={selected}
              value={"how"}
              setSelected={setSelected}
              text={"How It Works"}
            />

            <FaqButton
              selection={selected}
              value={"researchers"}
              setSelected={setSelected}
              text={"Researchers"}
            />

            <FaqButton
              selection={selected}
              value={"philanthropists"}
              setSelected={setSelected}
              text={"Philanthropists"}
            />
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="pt-6 space-y-10">
              {filteredQuestions.map((question) => (
                <React.Fragment key={question.question}>
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                            <span className="text-zinc-900 text-lg font-medium leading-[27px]">
                              {question.question}
                            </span>
                            <div className="w-6 h-6 relative">
                              <div className="w-6 h-6 left-0 top-0 absolute rounded-full border border-slate-300"></div>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-[9px] w-[9px] left-[7.50px] top-[7.50px] absolute transform"
                                )}
                                aria-hidden="true"
                              />
                            </div>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel
                          as="dd"
                          className="mt-3.5 text-neutral text-base font-normal leading-normal"
                        >
                          {question.answer}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <hr />
                </React.Fragment>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
