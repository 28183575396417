import ReactGA from "react-ga4";

const AnalyticsTracker = () => {
  const trackEvent = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const trackPageView = (page) => {
    ReactGA.send({ hitType: "pageview", page: page, title: page });
  };

  return { trackEvent, trackPageView };
};

export default AnalyticsTracker;
