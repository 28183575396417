import React from "react";
import { MessageBanner } from "../components";

export default function ClaimSuccess({ info, onClose }) {
  const introString = "You claimed this paper!";
  const color = "green";
  const message = (
    <React.Fragment>
      <p className="text-sm">{info.message}</p>
    </React.Fragment>
  );
  return (
    <MessageBanner
      color={color}
      introString={introString}
      message={message}
      onClose={onClose}
    />
  );
}
