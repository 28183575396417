/*
Page for minted research:

1. Shows all papers you've minted according to your wallet.
2. Input box to add another paper that you were a part of, but you're not on this page because you didn't mint it.
3. Consists of cards for each paper that lets you confirm the arxiv and see other information, including claiming.
*/
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import {
  ClaimFail,
  ClaimSuccess,
  PageLoading,
  PrizeCard,
  ResearchCard,
} from "../components";
import { ClientContext } from "../helpers/context";

function filterTokens(researchTokens, filterTo, myAddress) {
  if (filterTo == "own") {
    return researchTokens.filter((token) => token.heldBalance > 0);
  }

  if (filterTo == "claim") {
    return researchTokens.filter((token) => token.userClaim);
  }

  if (filterTo == "mint") {
    return researchTokens.filter(
      (token) => token.minter && token.minter.address == myAddress.toLowerCase()
    );
  }

  if (filterTo == "confirmed") {
    return researchTokens.filter((token) => token.verified === true);
  }

  // All tokens.
  return researchTokens.filter((token) => token.researchIdentifier != "");
}

function mergeTokens(tokens) {
  let ret = {};

  tokens.forEach((token) => {
    let address = token.address.toLowerCase();
    if (!ret.hasOwnProperty(address)) {
      ret[address] = token;
    } else {
      ["userClaim"].forEach((s) => {
        ret[address][s] = token[s] || ret[address][s];
      });
      if (token.userClaim) {
        ret[address].claimBalance = token.claimBalance;
      }
      if (token.hasOwnProperty("verified")) {
        ret[address].verified = token.verified;
      }
    }
  });

  return Object.values(ret).sort(function (a, b) {
    const aClaim = a.claimBalance ? a.claimBalance : 0;
    const bClaim = b.claimBalance ? b.claimBalance : 0;
    return bClaim - aClaim;
  });
}

export default function ResearchView({ track }) {
  return (
    <div className="min-h-screen pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl xs:px-3">
        <Research track={track} />
      </div>
    </div>
  );
}

function Research({ track }) {
  const { client } = useContext(ClientContext);
  const address = client && client.address;

  const filterOptions = address
    ? [
        { name: "All tokens", id: "all" },
        { name: "Verified tokens", id: "confirmed" },
        { name: "Claimable tokens", id: "claim" },
        { name: "My minted tokens", id: "mint" },
        { name: "My owned tokens", id: "own" },
      ]
    : [
        { name: "All tokens", id: "all" },
        { name: "Verified research", id: "confirmed" },
      ];

  const [filterTo, setFilterTo] = useState(filterOptions[0]);
  const [isMessageWindowVisible, setIsMessageWindowVisible] = useState(false);
  const [claimError, setClaimError] = useState("");
  const [claimSuccess, setClaimSuccess] = useState("");

  const [allResearchTokens, setAllResearchTokens] = useState([]);
  const [heldResearchTokens, setHeldResearchTokens] = useState([]);
  const [claimResearchTokens, setClaimResearchTokens] = useState([]);
  const [claimMerklePrizeTokensDB, setClaimMerklePrizeTokensDB] = useState([]);
  const [claimPrizeTokens, setClaimPrizeTokens] = useState([]);
  const [checkClaimResearchTokens, setCheckClaimResearchTokens] = useState([]);
  const [loadedAll, setLoadedAll] = useState(false);
  const [loadedHeld, setLoadedHeld] = useState(false);
  const [loadedClaimed, setLoadedClaimed] = useState(false);

  function onChangeSelect(value) {
    setFilterTo(value);
  }

  const selectFilter = (
    <SelectFilter
      selected={filterTo}
      options={filterOptions}
      onChange={onChangeSelect}
    />
  );

  const setVerifiedStatus = (address, verified) => {
    const tokens = allResearchTokens.map((token) => {
      if (token.address == address) {
        token.verified = verified;
      }
      return token;
    });
    setAllResearchTokens(tokens);
  };

  async function getMyOwnedResearchTokens() {
    try {
      const tokenBalancesByType = (balances, ty) => {
        return balances.filter((account) => {
          return account.type == ty;
        });
      };

      // distributions:  {
      //   status: 'success',
      //   tokens: [
      //     {
      //       ID: 85,
      //       CreatedAt: '2024-01-13T01:01:25.77939705Z',
      //       UpdatedAt: '2024-01-13T01:01:25.77939705Z',
      //       DeletedAt: null,
      //       userAddress: '0x37990c085180528ef52f55c7945ebbc5bc040e50',
      //       tokenAddress: '0x030074323af332c643d566043b5746b0fd12920d',
      //       distributorAddress: '0x74c90997193aa238db31105e80567cff20aff8d5',
      //       amountHex: '0x09e25a35daeac3ded26a',
      //       proof: '0xf0409dafbc5244b2f09174e602a6035df81bf0919f8dd400e5d6ac83826b1cff,0x9626243ea0b3e2d8531a58d1cdde9d9c5b455d1eb4d7b98b931cafb6eaa31c5a,0x27f51552cc17fa682f43fefde58136adee6b8c8783a121544b4746abd47a3cb4,0x2375338e165ea6320a09183c44fd3c3e53b8c69841ed45199536449926702c03,0x97b261bc7f2fa1dc4817b522a709f4dfff8be63e60599dc2442c1d2cce797dcc,0x05c1b0160a39480b40b6f28aa07a9b35ecbc70f77d0deb9d84dd6e56d7f6a8f7,0xcb5ddd30317e9b0c23c9409b67fc5056313a1e185b85f26f4949af52127bbb12,0x3057a6a6ec829b42750994ce9b54df37d6d0f5f7a3ef8ee4205dcefb716a67d9',
      //       index: 21,3
      //       claimed: false
      //     }
      //   ]
      // }
      const myPrizeDistributions_ =
        await client.getDatabaseDistributionsForAccount(address);
      console.log("MPD: ");
      console.log(myPrizeDistributions_);
      const myPrizeDistributions = myPrizeDistributions_.tokens.map((d) => {
        // Assuming here that we're using the Deploy USDC...
        const totalSupplyExact = BigInt(1000000000000000000000) * BigInt(246);
        return {
          userClaim: true,
          symbol: "USDC",
          name: "USDC Distributor",
          decimals: 18,
          amountHex: d.amountHex,
          address: d.distributorAddress,
          tokenAddress: d.tokenAddress,
          distributorAddress: d.distributorAddress,
          proof: d.proof,
          index: d.index,
          totalSupplyExact: totalSupplyExact,
          claimBalance: (parseInt(d.amountHex, 16) / 10 ** 18).toFixed(2),
        };
      });

      const balances = await client.getAllBalancesForAccount(address);
      // The USDC token, but the time it comes back, already has the wrong name.
      const claimTokens = tokenBalancesByType(balances, "UNCLAIMED").map(
        (balance) => ({
          ...balance.token,
          userClaim: true,
          claimBalance: parseInt(balance.value),
          totalSupplyExact: BigInt(balance.token.totalSupplyExact),
        })
      );
      console.log("CLAIM TOKEN");
      console.log(claimTokens);
      const claimTokensResearch = claimTokens.filter(
        (f) => f.researchIdentifier != null && f.researchIdentifier != ""
      );
      const claimTokensPrize = claimTokens.filter(
        (f) => f.researchIdentifier == "" || f.researchIdentifier == null
      );

      const heldTokens = tokenBalancesByType(balances, "HELD")
        .filter(
          (f) =>
            f.token.researchIdentifier != "" &&
            f.token.researchIdentifier != null
        )
        .map((balance) => ({
          ...balance.token,
          userClaim: false,
          heldBalance: parseInt(balance.value),
          totalSupplyExact: BigInt(balance.token.totalSupplyExact),
        }));

      console.log("HELD TOKENS");
      console.log(heldTokens);

      setHeldResearchTokens(heldTokens);
      setLoadedHeld(true);
      setClaimResearchTokens([]); // claimTokensResearch);
      setCheckClaimResearchTokens(
        claimTokensResearch // .map((token) => { address: token.address, )
      );
      setClaimMerklePrizeTokensDB(myPrizeDistributions);
      setClaimPrizeTokens(claimTokensPrize);
      setLoadedClaimed(true);
    } catch (error) {
      // TODO: show the user the error.
      console.log(error);
      setLoadedHeld(true);
      setHeldResearchTokens([]);
      setLoadedClaimed(true);
      setClaimResearchTokens([]);
      setClaimPrizeTokens([]);
    }
  }

  useEffect(() => {
    if (checkClaimResearchTokens.length) {
      Promise.all(
        checkClaimResearchTokens.map((token) => {
          return client.getToken(token.address);
        })
      ).then(
        (result) => {
          let newClaimResearchTokens = [];
          let newClaimPrizeTokens = [...claimPrizeTokens];
          for (var i = 0; i < result.length; i++) {
            const token = result[i];
            if (token.researchIdentifier) {
              newClaimResearchTokens.push(checkClaimResearchTokens[i]);
            } else {
              const newToken = {
                ...checkClaimResearchTokens[i],
                ...token,
              };
              newClaimPrizeTokens.push(newToken);
            }
          }
          setClaimResearchTokens(newClaimResearchTokens);
          setClaimPrizeTokens(newClaimPrizeTokens);
          setCheckClaimResearchTokens([]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [checkClaimResearchTokens]);

  useEffect(() => {
    if (address) {
      getMyOwnedResearchTokens();
    }
  }, [address]);

  useEffect(() => {
    async function getAllResearchTokens_() {
      setLoadedAll(false);
      client.getAllResearchTokens().then(
        (result) => {
          const allTokens = result.tokens.map((token) => {
            token["totalSupplyExact"] = BigInt(token.totalSupplyExact);
            return token;
          });
          setAllResearchTokens(allTokens);
          setLoadedAll(true);
        },
        (error) => {
          // TODO: show the user the error.
          console.log(error);
          setAllResearchTokens([]);
          setLoadedAll(true);
        }
      );
    }

    getAllResearchTokens_();
  }, []);

  if ((address && !loadedHeld) || (address && !loadedClaimed) || !loadedAll) {
    return (
      <div className="flex h-96">
        <div className="m-auto">
          <PageLoading />
        </div>
      </div>
    );
  }

  const allTokens = heldResearchTokens
    .concat(claimResearchTokens)
    .concat(allResearchTokens)
    .concat(claimPrizeTokens);
  if (!allTokens || allTokens.length == 0) {
    return <PurchaseMessage />;
  }

  // This is dumb. I'm just hacking away to get this working.
  const mergedTokens = mergeTokens(allTokens).concat(claimMerklePrizeTokensDB);
  const filteredTokens =
    mergedTokens.length > 0 && filterTo
      ? filterTokens(mergedTokens, filterTo.id, address)
      : allTokens;

  const setResearchCardClaimSuccess = (message) => {
    setClaimError("");
    setClaimSuccess(message);
    setIsMessageWindowVisible(true);
    getMyOwnedResearchTokens();
  };
  const setResearchCardClaimError = (message) => {
    setClaimSuccess("");
    setClaimError(message);
    setIsMessageWindowVisible(true);
  };
  const tokenCards = filteredTokens.map((token) => {
    const key = `${address}-${token.address}`;
    if (!token.researchIdentifier) {
      return (
        <PrizeCard
          myAddress={address}
          client={client}
          track={track}
          key={key}
          setClaimError={setResearchCardClaimError}
          setClaimSuccess={setResearchCardClaimSuccess}
          setVerifiedStatus={setVerifiedStatus}
          token={token}
        />
      );
    } else {
      return (
        <ResearchCard
          myAddress={address}
          track={track}
          client={client}
          key={key}
          setClaimError={setResearchCardClaimError}
          setClaimSuccess={setResearchCardClaimSuccess}
          setVerifiedStatus={setVerifiedStatus}
          token={token}
        />
      );
    }
  });

  let banner;
  if (claimError && isMessageWindowVisible) {
    banner = (
      <ClaimFail
        info={claimError}
        onClose={() => setIsMessageWindowVisible(false)}
      />
    );
  } else if (claimSuccess && isMessageWindowVisible) {
    banner = (
      <ClaimSuccess
        info={claimSuccess}
        onClose={() => setIsMessageWindowVisible(false)}
      />
    );
  } else {
    banner = null;
  }

  return (
    <React.Fragment>
      {banner}
      <div className="space-y-6">
        {selectFilter}
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          {tokenCards}
        </ul>
      </div>
    </React.Fragment>
  );
}

function SelectFilter({ options, selected, onChange }) {
  return (
    <Listbox id="selectFilter" value={selected} onChange={onChange}>
      <div className="relative mt-1">
        <Listbox.Button className="relative md:w-1/6 sm:w-full py-2 pl-3 pr-10 text-center bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
          <span className="block truncate">{selected.name}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute md:w-full sm:w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option, optionIndex) => {
              return (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                        cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {option.name}
                      </span>
                      {selected ? (
                        <span
                          className={`${
                            active ? "text-amber-600" : "text-amber-600"
                          }
                              absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

function PurchaseMessage() {
  return (
    <div className="flex" style={{ height: "50vh" }}>
      <div className="m-auto">
        <p className="sm:text-lg text-center font-medium text-indigo-600 leading-6 xs:text-base">
          We searched far and wide but cannot find any research for your
          connected wallet.
        </p>
      </div>
    </div>
  );
}
