
// <T>(fn: () => Promise<T>, delay?: number): Promise<T>{
export async function pollWhile<T>(fn: () => Promise<T>, delay?: number): Promise<T> {
  if (delay === undefined) {
    delay = 1000
  }
  while (true) {
    const value = await fn()
    if (value) {
      return value
    }
    await new Promise((y, n) => setTimeout(y, delay))
  }
}
