import { CalendarIcon } from "@heroicons/react/outline"
import { timeConverter } from '../helpers/format';
import { NETWORK } from '../constants'

export default function DeployTransaction({transaction}) {
    const {hash, timestamp} = transaction
    const withHours = false
    const formattedTime = timeConverter(parseInt(timestamp), withHours)
    const etherscanTxnUrl = NETWORK.blockExplorer + "tx/" + hash;
    const icon = <CalendarIcon className="w-4 h-4" />

    return (
    <a href={etherscanTxnUrl} className="cursor-pointer inline-flex items-center justify-center py-2 text-sm text-gray-700 hover:text-blue-800 visited:text-purple-600" target="_blank">
        {icon}
        <span className="ml-1">{formattedTime}</span>
    </a>
    )
}