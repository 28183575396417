import { useState } from "react";
import { ViewGridAddIcon } from "@heroicons/react/outline"
import { addToWallet } from "../helpers/wallet"

export default function AddToWallet({address, symbol, decimals}) {
    const [added, setAdded] = useState(false);
    
    const add = function() {
        const callback = (hasError, errorMessage) => {
            if (hasError) {
                // TODO: post this for user.
                console.log(errorMessage);                
            } 
            setAdded(hasError)
        }
        addToWallet(address, symbol, decimals, callback)
    }

    const icon = added ? (
        <ViewGridAddIcon className="w-4 h-4" aria-hidden="true" />
    ) : (
        <ViewGridAddIcon className="w-4 h-4" style={{color: "#52c41a"}} aria-hidden="true" />
    )

    return (
    <div onClick={(e) => add()} className="cursor-pointer inline-flex items-center justify-center py-2 text-sm text-gray-700">            
        {icon}
        <span className="ml-1 hover:text-gray-500">{added ? "Tracked!" : "Track in Wallet"}</span>
    </div>   
    )
}
